import React from 'react';
import 'animate.css';
import 'semantic-ui-css/semantic.min.css';
import {
	Modal,
	Icon,
	Form,
	Grid,
	Button,
	Input,
	Message,
	Dropdown,
	TextArea
} from 'semantic-ui-react';

import RequestManager from './RequestManager';

class EditProfile extends React.Component {

	state = {
		newIndustryExperience: '',
		educationOptions: false,
		userStatus: this.props.userStatus,
		isLoading: false,
		hasError: false,
		errorTitle: '',
		errorMessage: '',
		errorField: '',
		hasLinkedinURL: false,
		form: {
			firstName: '',
			lastName: '',
			gender: '',
			tax_id: '',
			phone: '',
			region: '',
			city: '',
			englishLevel: '',
			ethnicity: '',
			educationalLevel: '',
			university: '',
			course: '',
			undergradStudent: '',
			currentSemester: '',
			graduationYear: '',
			developmentExperience: '',
			currentEmployment: '',
			linkedinURL: '',
			professionalExperience: '',
			industryExperience: '',
			salesforceExperience: '',
			trailheadProfileURL: '',
			withDisability: '',
			disabilityDescription: ''
		},
		activeFormStep: 0,
		regionList: [
			{ key: 'AC', value: 'AC', text: 'Acre' },
			{ key: 'AL', value: 'AL', text: 'Alagoas' },
			{ key: 'AP', value: 'AP', text: 'Amapá' },
			{ key: 'AM', value: 'AM', text: 'Amazonas' },
			{ key: 'BA', value: 'BA', text: 'Bahia' },
			{ key: 'CE', value: 'CE', text: 'Ceará' },
			{ key: 'DF', value: 'DF', text: 'Distrito Federal' },
			{ key: 'ES', value: 'ES', text: 'Espírito Santo' },
			{ key: 'GO', value: 'GO', text: 'Goiás' },
			{ key: 'MA', value: 'MA', text: 'Maranhão' },
			{ key: 'MT', value: 'MT', text: 'Mato Grosso' },
			{ key: 'MS', value: 'MS', text: 'Mato Grosso do Sul' },
			{ key: 'MG', value: 'MG', text: 'Minas Gerais' },
			{ key: 'PA', value: 'PA', text: 'Pará' },
			{ key: 'PB', value: 'PB', text: 'Paraíba' },
			{ key: 'PR', value: 'PR', text: 'Paraná' },
			{ key: 'PE', value: 'PE', text: 'Pernambuco' },
			{ key: 'PI', value: 'PI', text: 'Piauí' },
			{ key: 'RJ', value: 'RJ', text: 'Rio de Janeiro' },
			{ key: 'RN', value: 'RN', text: 'Rio Grande do Norte' },
			{ key: 'RS', value: 'RS', text: 'Rio Grande do Sul' },
			{ key: 'RO', value: 'RO', text: 'Rondônia' },
			{ key: 'RR', value: 'RR', text: 'Roraima' },
			{ key: 'SC', value: 'SC', text: 'Santa Catarina' },
			{ key: 'SP', value: 'SP', text: 'São Paulo' },
			{ key: 'SE', value: 'SE', text: 'Sergipe' },
			{ key: 'TO', value: 'TO', text: 'Tocantins' },
			{ key: 'EX', value: 'EX', text: 'Exterior' }
		],
		genderList: [
			{ key: 'F', value: 'F', text: 'Feminino' },
			{ key: 'M', value: 'M', text: 'Masculino' },
			{ key: 'O', value: 'O', text: 'Outros' }
		],
		educationalLevelList: [
			{ key: 'Ensino Fundamental', value: 'Ensino Fundamental', text: 'Ensino Fundamental' },
			{ key: 'Ensino Fundamental (incompleto)', value: 'Ensino Fundamental (incompleto)', text: 'Ensino Fundamental (incompleto)' },
			{ key: 'Ensino Médio', value: 'Ensino Médio', text: 'Ensino Médio' },
			{ key: 'Ensino Médio (incompleto)', value: 'Ensino Médio (incompleto)', text: 'Ensino Médio (incompleto)' },
			{ key: 'Tecnólogo', value: 'Tecnólogo', text: 'Tecnólogo' },
			{ key: 'Ensino Superior', value: 'Ensino Superior', text: 'Ensino Superior' },
			{ key: 'Ensino Superior (incompleto)', value: 'Ensino Superior (incompleto)', text: 'Ensino Superior (incompleto)' },
			{ key: 'Ensino Superior (cursando)', value: 'Ensino Superior (cursando)', text: 'Ensino Superior (cursando)' },
			{ key: 'Pós Graduação', value: 'Pós Graduação', text: 'Pós Graduação' },
			{ key: 'Mestrado', value: 'Mestrado', text: 'Mestrado' },
			{ key: 'Doutorado', value: 'Doutorado', text: 'Doutorado' }
		],
		englishLevelList:[
			{ key: 'Nenhum', value: 'Nenhum', text: 'Nenhum' },
			{ key: 'Básico', value: 'Básico', text: 'Básico' },
			{ key: 'Leitura e escrita', value: 'Leitura e escrita', text: 'Leitura e escrita' },
			{ key: 'Intermediário', value: 'Intermediário', text: 'Intermediário' },
			{ key: 'Fluente', value: 'Fluente', text: 'Fluente' }
		],
		currentSemesterList:[
			{ key: '1º', value: '1º', text: '1º' },
			{ key: '2º', value: '2º', text: '2º' },
			{ key: '3º', value: '3º', text: '3º' },
			{ key: '4º', value: '4º', text: '4º' },
			{ key: '5º', value: '5º', text: '5º' },
			{ key: '6º', value: '6º', text: '6º' },
			{ key: '7º', value: '7º', text: '7º' },
			{ key: '8º', value: '8º', text: '8º' },
			{ key: '9º', value: '9º', text: '9º' },
			{ key: '10º', value: '10º', text: '10º' },
			{ key: '11º', value: '11º', text: '11º' },
			{ key: '12º', value: '12º', text: '12º' }
		],
		graduationYearList:[
			{ key: '2022', value: '2022', text: '2022' },
			{ key: '2023', value: '2023', text: '2023' },
			{ key: '2024', value: '2024', text: '2024' },
			{ key: '2025', value: '2025', text: '2025' },
			{ key: '2026', value: '2026', text: '2026' },
			{ key: '2027', value: '2027', text: '2027' },
			{ key: '2028', value: '2028', text: '2028' },
			{ key: '2029', value: '2029', text: '2029' },
			{ key: '2030', value: '2030', text: '2030' },
			{ key: '2031', value: '2031', text: '2031' }
		],
		developmentExperienceList: [
			{ key: 'Nenhuma', value: 'Nenhuma', text: 'Nenhuma' },
			{ key: 'Já fiz alguns cursos', value: 'Já fiz alguns cursos', text: 'Já fiz alguns cursos' },
			{ key: 'Estudei na faculdade', value: 'Estudei na faculdade', text: 'Estudei na faculdade' },
			{ key: 'Trabalho com desenvolvimento', value: 'Trabalho com desenvolvimento', text: 'Trabalho com desenvolvimento' }
		],
		currentEmploymentList:[
			{ key: 'Não', value: 'Não', text: 'Não' },
			{ key: 'Sim, CLT', value: 'CLT', text: 'Sim, CLT' },
			{ key: 'Sim, como autônomo/freelancer', value: 'Autônomo/Freelancer', text: 'Sim, como autônomo/freelancer' },
			{ key: 'Sim, faço estágio', value: 'Estágio', text: 'Sim, faço estágio' }
		],
		industryExperienceList:[
			{ key: 'None', value: 'None', text: 'Nenhuma' },
			{ key: 'Automotive', value: 'Automotive', text: 'Automotiva' },
			{ key: 'Education', value: 'Education', text: 'Educação' },
			{ key: 'Pharma & Biotech', value: 'Pharma & Biotech', text: 'Farmacêutica e Biotecnologia' },
			{ key: 'Manufacturing', value: 'Manufacturing', text: 'Manufatura' },
			{ key: 'Media', value: 'Media', text: 'Meios de Comunicação' },
			{ key: 'NGOs & Non-Profit Organizations', value: 'NGOs & Non-Profit Organizations', text: 'ONG e Organizações sem fins lucrativos' },
			{ key: 'Health', value: 'Health', text: 'Saúde' },
			{ key: 'Utilities', value: 'Utilities', text: 'Utilities(Água e Saneamento, Energia, Petróleo e Gás)' },
			{ key: 'Financial Services', value: 'Financial Services', text: 'Serviços Financeiros' },
			{ key: 'Public Sector', value: 'Public Sector', text: 'Setor Público e Governo' },
			{ key: 'Technology', value: 'Technology', text: 'Tecnologia' },
			{ key: 'Telco', value: 'Telco', text: 'Telecomunicações' },
			{ key: 'Retail and Consumer Goods', value: 'Retail and Consumer Goods', text: 'Varejo e Bens de Consumo' },
		],
		salesforceExperienceList:[
			{ key: 'Nenhuma', value: 'Nenhuma', text: 'Nenhuma' },
			{ key: 'EstudeiTrailhead', value: 'Já estudei no Trailhead', text: 'Já estudei no Trailhead' },
			{ key: 'Ranger', value: 'Sou Ranger', text: 'Sou Ranger' },
			{ key: 'Certificado', value: 'Sou certificado', text: 'Sou Certificado' }
		],
		ethnicityList:[
			{ key: 'Amarela', value: 'Amarela', text: 'Amarela' },
			{ key: 'Branca', value: 'Branca', text: 'Branca' },
			{ key: 'Indígena', value: 'Indígena', text: 'Indígena' },
			{ key: 'Parda', value: 'Parda', text: 'Parda' },
			{ key: 'Preta', value: 'Preta', text: 'Preta' },
			{ key: 'Prefiro não declarar', value: 'Prefiro não declarar', text: 'Prefiro não declarar' }
		]
	};

	previousVisible = null;

	constructor(state){
		super(state);
		this.receivedProfileData = this.receivedProfileData.bind(this);
		this.previousButtonHandler = this.previousButtonHandler.bind(this);
		this.nextButtonHandler = this.nextButtonHandler.bind(this);
		this.saveButtonHandler = this.saveButtonHandler.bind(this);
		this.handleValueChange = this.handleValueChange.bind(this);
    	this.receivedResponse = this.receivedResponse.bind(this);
		this.setModalSkills = this.setModalSkills.bind(this);
	}
	
	componentDidMount(){
		this.loadProfile();
	}

	// componentDidUpdate(){
	// 	if(this.previousVisible !== this.props.open && this.props.open){
	// 		this.loadProfile();
	// 	}
	// }

	shouldComponentUpdate(nextProps, nextState){
		this.previousVisible = this.props.open;
		this.previousVisiblePersonality = this.props.modalSkills;
		return true;
	}

	loadProfile(){
		this.setState(function(state){
			state.userStatus = this.props.userStatus;
			state.activeFormStep = 0;
			state.isLoading = true;
			state.hasError = false;
			state.errorTitle = '';
			state.errorMessage = '';
			state.errorField = '';
			return state;
		});
		RequestManager.requestAPI(
			'/student/profile/load',
			{},
			this.receivedProfileData
		);
	}

	receivedProfileData(hasError, receivedData) {
		this.setState(function(state){
			state.isLoading = false;
			state.form.firstName = receivedData.firstName || '';
			state.form.lastName = receivedData.lastName || '';
			state.form.gender = receivedData.gender || '';
			state.form.tax_id = receivedData.taxId || '';
			state.form.phone = receivedData.phoneNumber || '';
			state.form.region = receivedData.region || '';
			state.form.city = receivedData.city || '';
			state.form.linkedinURL = receivedData.linkedinURL || '';
			state.form.educationalLevel = receivedData.educationalLevel || '';
			state.form.englishLevel = receivedData.englishLevel || '';
			state.form.currentEmployment = receivedData.currentEmployment || '';
			state.form.professionalExperience = receivedData.professionalExperience || '';
			state.form.developmentExperience = receivedData.developmentExperience || '';
			state.form.course = receivedData.course || '';
			state.form.university = receivedData.university || '';
			state.form.ethnicity = receivedData.ethnicity || '';
			state.form.currentSemester = receivedData.currentSemester || '';
			state.form.graduationYear = receivedData.graduationYear || '';
			state.form.industryExperience = receivedData.industryExperience ? receivedData.industryExperience.split(',') : [''];
			state.form.salesforceExperience = receivedData.salesforceExperience || '';
			state.form.trailheadProfileURL = receivedData.trailheadProfileURL || '';
			state.form.withDisability = receivedData.withDisability || '';
			state.form.disabilityDescription = receivedData.disabilityDescription || '';
			state.newIndustryExperience = receivedData.industryExperience ? receivedData.industryExperience.split(',') : [''];
			state.hasLinkedinURL = Boolean(receivedData.linkedinURL && receivedData.linkedinURL.length > 0)
			return state;
		});
  }

	handleValueChange(value, name){
		const data = value;
		this.setState(function(state) {
			if(name === 'newIndustryExperience') {
				state.form.industryExperience = data.length > 0 ? data.toString() : ' ';
				state.newIndustryExperience = data;
			} else if(data > ' ') {
				state.form[name] = data;
			} else {
				state.form[name] = null;
			}
			return state;
		});
	}

	closePopUP(){
		this.setState(function(state){
			state.isLoading = false;
			state.hasError = false;
			return state;
		});
	}

	previousButtonHandler(){
		var activeFormStep = this.state.activeFormStep;
		if (activeFormStep > 0){
			this.setState(function(state){
				state.activeFormStep = activeFormStep - 1;
				return state;
			});
		}
	}

	nextButtonHandler(){
		if(this.validateFields()){
			var activeFormStep = this.state.activeFormStep;
			this.setState(function(state){
				state.errorField = '';
				state.errorTitle = '';
				state.errorMessage = '';
				state.hasError = false;
				state.activeFormStep = activeFormStep + 1;
				state.form.industryExperience = state.newIndustryExperience.length > 0 ? state.newIndustryExperience.toString() : ' ';
				return state;
			});
		}
	}

	saveButtonHandler(){
		this.setLoadingState(true);
		
		if(this.validateFields()){
			RequestManager.requestAPI(
				'/student/profile/save',
				this.state.form,
				this.receivedResponse
			);
		}
	}

	receivedResponse(error, data) {
		var hasError = false;
		var errorField = '';
		var errorTitle = '';
		var errorMessage = '';
		if(error){
			if (error > 0){
				hasError = true;
				errorTitle = 'Falha na gravação!';
				errorMessage = 'Não foi possível gravar o seu perfil, tente novamente em alguns instantes.';
			}
		}
		this.setState(function(state){
			state.activeFormStep = 0;
			state.isLoading = false;
			state.hasError = hasError;
			state.errorField = errorField;
			state.errorTitle = errorTitle;
			state.errorMessage = errorMessage;
			return state;
		});
		if (!hasError) {
			if(typeof this.props.onDone === 'function'){
				this.props.onDone();
			}else if(typeof this.props.onCancelClick === 'function'){
				this.props.onCancelClick();
			}
			this.closePopUP();
		}
	}

	setLoadingState(isLoading){
		this.setState(function(state){
			state.isLoading = isLoading;
			return state;
		});
	}

	validateFields(){
		var hasError = false;
		var errorField = null;
		var errorTitle = null;
		var errorMessage = null;
		var activeFormStep = this.state.activeFormStep;
		if (activeFormStep === 0){
			if (this.state.form.firstName <= ''){
				hasError = true;
				errorField = 'firstName';
				errorTitle = 'Informe o seu Primeiro Nome';
			}else if (this.state.form.lastName <= ''){
				hasError = true;
				errorField = 'lastName';
				errorTitle = 'Informe o seu Sobrenome';
			}else if (this.state.form.tax_id <= ''){
				hasError = true;
				errorField = 'tax_id';
				errorTitle = 'Informe seu CPF';
			}else if (this.state.form.phone <= ''){
				hasError = true;
				errorField = 'phone';
				errorTitle = 'Informe seu Telefone de contato';
			}else if (this.state.form.city <= ''){
				hasError = true;
				errorField = 'city';
				errorTitle = 'Informe a sua Cidade';
			}else if (this.state.form.ethnicity <= ''){
				hasError = true;
				errorField = 'ethnicity';
				errorTitle = 'Informe a sua etnia';
			}
		} else if (activeFormStep === 1) {
			if (this.state.form.englishLevel <= ''){
				hasError = true;
				errorField = 'englishLevel';
				errorTitle = 'Informe seu nível de Inglês';
			}else if (this.state.form.educationalLevel <= ''){
				hasError = true;
				errorField = 'educationalLevel';
				errorTitle = 'Informe o nível Educacional';
			}else if(this.state.educationOptions) {
				if (this.state.form.university <= ''){
					hasError = true;
					errorField = 'course';
					errorTitle = 'Informe o nome da Universidade';
				}else if (this.state.form.course <= ''){
					hasError = true;
					errorField = 'course';
					errorTitle = 'Informe o nome do curso';
				}else if (this.state.form.currentSemester <= '' && this.state.form.educationalLevel === 'Ensino Superior (cursando)'){
					hasError = true;
					errorField = 'currentSemester';
					errorTitle = 'Informe em que período/semestre você se encontra';
				}else if (this.state.form.graduationYear <= '' && this.state.form.educationalLevel === 'Ensino Superior (cursando)'){
					hasError = true;
					errorField = 'graduationYear';
					errorTitle = 'Informe o ano previsto de conclusão';
				}			
			}else if (this.state.form.currentEmployment <= ''){
				hasError = true;
				errorField = 'currentEmployment';
				errorTitle = 'Informe se está trabalhando';
			}else if (this.state.form.developmentExperience <= ''){
				hasError = true;
				errorField = 'developmentExperience';
				errorTitle = 'Informe sua experiência com desenvolvimento';
			}else if (this.state.form.linkedinURL <= ''){
				hasError = true;
				errorField = 'linkedinURL';
				errorTitle = 'Informe a URL do seu LinkedIn';
			}
		} else if (activeFormStep === 2) {
			 if (this.state.form.salesforceExperience <= ''){
				hasError = true;
				errorField = 'salesforceExperience';
				errorTitle = 'Informe se possui conhecimento em Salesforce';
			}
		}
		if (hasError && errorMessage === null){
			errorMessage = 'Preencha os campos obrigatórios e tente novamente.';
		}
		if (hasError){
			this.setState(function(state){
				state.isLoading = false;
				state.hasError = true;
				state.errorField = errorField;
				state.errorTitle = errorTitle;
				state.errorMessage = errorMessage;
				return state;
			});
			return false;
		}
		return true;
	}

	handleFormChange(event, fieldName, substring){
		var data = event.target.value;
		if(typeof substring != 'undefined'){
			data = data.substring(0, substring);
		}
		this.setState(function(state){
			state.form[fieldName] = data;
			return state;
		});
	}

	isValidCPF(cpf) {
		if (typeof cpf !== "string") return false
		cpf = cpf.replace(/[\s.-]*/igm, '')
		if (
			!cpf ||
			cpf.length !== 11 ||
			cpf === "00000000000" ||
			cpf === "11111111111" ||
			cpf === "22222222222" ||
			cpf === "33333333333" ||
			cpf === "44444444444" ||
			cpf === "55555555555" ||
			cpf === "66666666666" ||
			cpf === "77777777777" ||
			cpf === "88888888888" ||
			cpf === "99999999999" 
		) {
			return false
		}
		var soma = 0
		var resto
		for (let i = 1; i <= 9; i++) 
		  soma = soma + parseInt(cpf.substring(i-1, i)) * (11 - i)
		resto = (soma * 10) % 11
		if ((resto === 10) || (resto === 11))  resto = 0
		if (resto !== parseInt(cpf.substring(9, 10)) ) return false
		soma = 0
		for (let i = 1; i <= 10; i++) 
		  soma = soma + parseInt(cpf.substring(i-1, i)) * (12 - i)
		resto = (soma * 10) % 11
		if ((resto === 10) || (resto === 11)) resto = 0
		if (resto !== parseInt(cpf.substring(10, 11) ) ) return false
		return true
	}

	setModalSkills(){
		this.setState((state) => {
			state.openModalSkills = !this.state.openModalSkills
			return state
		})
	}

	render(){
		return (
		<>
			<Modal open={this.props.open} className="animated fadeInDown" size="small" >
				<Modal.Header>
					<Icon name="user" />
					{this.state.userStatus==="P"?"Falta completar o seu Perfil":"Editar o Perfil"}
				</Modal.Header>
				<Modal.Content>
					{
						(this.state.hasError) &&
						<Message negative className="animated fadeInDown">
							<Message.Header>{this.state.errorTitle}</Message.Header>
							<p>{this.state.errorMessage}</p>
						</Message>
					}
					{
						this.state.activeFormStep === 0
						&&	<Form>
								<Grid relaxed columns="2">
									<Grid.Column>
										<Form.Field required>
											<label>Nome</label>
											<Input  placeholder='' value={this.state.form.firstName} onChange={(event)=>{this.handleFormChange(event,'firstName')}} />
										</Form.Field>
									</Grid.Column>
									<Grid.Column>
										<Form.Field required>
											<label>Sobrenome</label>
											<Input placeholder='' value={this.state.form.lastName} onChange={(event)=>{this.handleFormChange(event,'lastName')}} />
										</Form.Field>
									</Grid.Column>
									<Grid.Column>
										<Form.Field required>
											<label>Gênero</label>
											<Dropdown 
												disabled={this.state.isLoading} 
												value={this.state.form.gender} 
												fluid
												selection
												onChange={(event, {value})=>{this.handleValueChange(value,'gender')}}  
												options={this.state.genderList}
											/>
										</Form.Field>
									</Grid.Column>
									<Grid.Column>
										<Form.Field required>
											<label>CPF</label>
											<Input disabled={!(this.state.form.tax_id.length <= 11)} placeholder='' value={this.state.form.tax_id} maxLength={11}
												onChange={(event)=>{
													let valid = this.isValidCPF(event.target.value);
													this.setState(function(state){
														state.isLoading = false;
														state.hasError = !valid;
														state.errorField = valid ? "" : "tax_id" ;
														state.errorTitle = valid ? "" : "CPF Inválido";
														state.errorMessage = valid ? "" : "Insira um CPF válido";
														return state;
													});
													this.handleFormChange(event,'tax_id');
												}}
											/>
										</Form.Field>
									</Grid.Column>
									<Grid.Column>
										<Form.Field required>
											<label>Celular (com DDD)</label>
											<Input disabled={this.state.isLoading} value={this.state.form.phone} onChange={(event)=>{this.handleFormChange(event,'phone')}} />
										</Form.Field>
									</Grid.Column>
									<Grid.Column>
										<Form.Field required>
											<label>Etnia</label>
											<Dropdown 
												disabled={this.state.isLoading} 
												value={this.state.form.ethnicity} 
												fluid
												selection
												onChange={(event, {value})=>{this.handleValueChange(value,'ethnicity')}}  
												options={this.state.ethnicityList}
											/>
										</Form.Field>
									</Grid.Column>
									<Grid.Column>
										<Form.Field>
											<label>Pessoa com necessidades especials?</label>
											<Dropdown 
												value={this.state.form.withDisability} 
												fluid
												selection
												onChange={(event, {value})=>{this.handleValueChange(value,'withDisability')}}  
												options={[
													{ key: 'Sim', value: 'Sim', text: 'Sim' },
													{ key: 'Não', value: 'Não', text: 'Não' }
												]}
											/>
										</Form.Field>
									</Grid.Column>
									<Grid.Column>
										<Form.Field>
											<label>Quais?</label>
											<Input
												disabled={Boolean(this.state.form.withDisability && this.state.form.withDisability === 'Não')}
												value={this.state.form.disabilityDescription} 
												onChange={(event, {value})=>{this.handleValueChange(value,'disabilityDescription')}}  
											/>
										</Form.Field>
									</Grid.Column>
									<Grid.Column>
										<Form.Field required>
											<label>Região</label>
											<Dropdown 
												disabled={this.state.isLoading} 
												value={this.state.form.region} 
												fluid
												selection
												onChange={(event, {value})=>{this.handleValueChange(value,'region')}}  
												options={this.state.regionList}
											/>
										</Form.Field>
									</Grid.Column>
									<Grid.Column>
										<Form.Field required>
											<label>Cidade</label>
											<Input disabled={this.state.isLoading} placeholder='' value={this.state.form.city} onChange={(event)=>{this.handleFormChange(event,'city')}} />
										</Form.Field>
									</Grid.Column>									
								</Grid>
							</Form>
					}
					{
						this.state.activeFormStep === 1
						&&	<Form>
								<Grid relaxed columns="2">
									<Grid.Column>
										<Form.Field required>
											<label>Qual seu nível de Inglês?</label>
											<Dropdown 
												disabled={this.state.isLoading} 
												value={this.state.form.englishLevel} 
												fluid
												selection
												onChange={(event, {value})=>{this.handleValueChange(value,'englishLevel')}}  
												options={this.state.englishLevelList}
											/>
										</Form.Field>
									</Grid.Column>
									<Grid.Column>
										<Form.Field required>
											<label>Qual o seu nível de escolaridade</label>
											<Dropdown 
												disabled={this.state.isLoading} 
												value={this.state.form.educationalLevel} 
												fluid
												selection
												onChange={(event, {value}) => {
													this.handleValueChange(value,'educationalLevel')
													if(value !== 'Ensino Fundamental' && value !== 'Ensino Fundamental (incompleto)' && value !== 'Ensino Médio' && value !== 'Ensino Médio (incompleto)') {
														this.setState(function(state) {
															state.educationOptions = true
															return state
														})
													} else {
														this.setState(function(state) {
															state.educationOptions = false
															return state
														})
													}
												}}  
												options={this.state.educationalLevelList}
											/>
										</Form.Field>
									</Grid.Column>
									{this.state.educationOptions && <>
										<Grid.Column>
											<Form.Field required>
												<label>Em qual Universidade?</label>
												<Input disabled={this.state.isLoading} placeholder='' value={this.state.form.university} onChange={(event)=>{this.handleFormChange(event,'university')}} />
											</Form.Field>
										</Grid.Column>
										<Grid.Column>
											<Form.Field required>
												<label>Curso</label>
												<Input disabled={this.state.isLoading} placeholder='' value={this.state.form.course} onChange={(event)=>{this.handleFormChange(event,'course')}} />
											</Form.Field>
										</Grid.Column>
										{this.state.form.educationalLevel === 'Ensino Superior (cursando)' && <>
											<Grid.Column>
												<Form.Field required>
													<label>Qual período/semestre?</label>
													<Dropdown 
														disabled={this.state.isLoading} 
														value={this.state.form.currentSemester} 
														fluid
														selection
														onChange={(event, {value})=>{this.handleValueChange(value,'currentSemester')}}  
														options={this.state.currentSemesterList}
													/>
												</Form.Field>
											</Grid.Column>
											<Grid.Column>
												<Form.Field required>
													<label>Qual a previsão de conclusão?</label>
													<Dropdown 
														disabled={this.state.isLoading} 
														value={this.state.form.graduationYear} 
														fluid
														selection
														onChange={(event, {value})=>{this.handleValueChange(value,'graduationYear')}}  
														options={this.state.graduationYearList}
													/>
												</Form.Field>
											</Grid.Column>
										</> }
									</> }
									<Grid.Column>									
										<Form.Field required>
											<label>Qual a sua experiência com desenvolvimento?</label>
											<Dropdown 
												disabled={this.state.isLoading} 
												value={this.state.form.developmentExperience} 
												fluid
												selection
												onChange={(event, {value})=>{this.handleValueChange(value,'developmentExperience')}}  
												options={this.state.developmentExperienceList}
											/>
										</Form.Field>
									</Grid.Column>
									<Grid.Column>
										<Form.Field required>
											<label>Atualmente você está trabalhando?</label>
											<Dropdown 
												disabled={this.state.isLoading} 
												value={this.state.form.currentEmployment} 
												fluid
												selection
												onChange={(event, {value})=>{this.handleValueChange(value,'currentEmployment')}}  
												options={this.state.currentEmploymentList}
											/>
										</Form.Field>
									</Grid.Column>
									<Grid.Column>
										<Form.Field required>
											<label>Perfil no LinkedIn (URL)</label>
											<Input disabled={this.state.hasLinkedinURL} placeholder='linkedin.com/in/your_user_name/' value={this.state.form.linkedinURL} onChange={(event)=>{this.handleFormChange(event,'linkedinURL')}} />
										</Form.Field>
									</Grid.Column>
								</Grid>							
							</Form>
					}
					{
						this.state.activeFormStep === 2 &&	<Form>
							<Grid relaxed columns="1">
								<Grid.Column>
									<Form.Field>
										<label>Em que indústrias você tem experiência?</label>
										<Dropdown 
											disabled={this.state.isLoading} 
											value={this.state.newIndustryExperience || ['']}
											fluid
											multiple
											selection
											onChange={(event, {value})=>{this.handleValueChange(value,'newIndustryExperience')}}  
											options={this.state.industryExperienceList}
										/>
									</Form.Field>
								</Grid.Column>
							</Grid>
							<Grid relaxed columns="2">
								<Grid.Column>
									<Form.Field required>
										<label>Você possui conhecimento em Salesforce?</label>
										<Dropdown 
											disabled={this.state.isLoading} 
											value={this.state.form.salesforceExperience} 
											fluid
											selection
											onChange={(event, {value})=>{this.handleValueChange(value,'salesforceExperience')}}  
											options={this.state.salesforceExperienceList}
										/>
									</Form.Field>
								</Grid.Column>
								<Grid.Column>
									<Form.Field>
										<label>Perfil no Trailhead (URL)</label>
										<Input disabled={this.state.isLoading} placeholder='trailblazer.me/id/[profile]'
											value={this.state.form.trailheadProfileURL}
											onChange={(event)=>{this.handleFormChange(event,'trailheadProfileURL')}}
										/>
									</Form.Field>
								</Grid.Column>
							</Grid>
							<Grid relaxed columns="1">
								<Grid.Column>
									<Form.Field>
										<label>Faça um resumo da sua Experiência profissional</label>
										<TextArea rows="3" disabled={this.state.isLoading} placeholder='' value={this.state.form.professionalExperience} onChange={(event)=>{this.handleFormChange(event,'professionalExperience')}}/>
									</Form.Field>
								</Grid.Column>
							</Grid>
						</Form>
					}
				</Modal.Content>
				<Modal.Actions>
					{	this.state.userStatus !== "P" && this.state.activeFormStep === 0
						?	<Button disabled={this.state.isLoading} onClick={()=>{this.props.onCancelClick(); this.closePopUP()}}>
								Cancelar
							</Button>
						:	<Button disabled={this.state.isLoading} onClick={this.previousButtonHandler}>
								Voltar
							</Button>
					}
					{	(this.state.activeFormStep === 0 || this.state.activeFormStep === 1) &&
							<Button disabled={this.state.isLoading} primary onClick={this.nextButtonHandler}>
								Avançar
							</Button>
						
					}
					{	this.state.activeFormStep === 2
						?	<Button loading={this.state.isLoading} primary onClick={this.saveButtonHandler}>
								Salvar
							</Button>
						:	<></>
					}
				</Modal.Actions>
			</Modal>
		</>);
	}
}

export default EditProfile;