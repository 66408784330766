import React from 'react';
import 'animate.css';
import 'semantic-ui-css/semantic.min.css';
import './TrailQuiz.css';
import './Markdown.css';
import {
	Modal,
	Button,
	Segment,
	Label,
	Header
} from 'semantic-ui-react';

import ReactMarkdown from 'react-markdown';
import CodeBlock from "./CodeBlock";
import RequestManager from './RequestManager';

class TrailQuiz extends React.Component {

	state = {
        journeyId: null,
		trailId: null,
		quizId: null,
        questions: null,
        questionMap: null,
		activeQuestionIndex: 0,
		moveToQuestionIndex: 0,
		type: null,
		timeLimit: null,
		remainingTime: null,
		timerDisplay: null,
		endTime: null,
		isOpen: this.props.open,
		isLoading: true,
		isProcessing: false,
		isShowingAnswerDetails: false,
		isFinishPending: false,
		hasPendingQuestions: true,
		hasError: false
	};

	previousVisible = null;
	timerDisplaySetTimeout = null;

	constructor(state){
		super(state);
		this.receivedQuizData = this.receivedQuizData.bind(this);
		this.answerClickHandler = this.answerClickHandler.bind(this);
		this.sendAnswer = this.sendAnswer.bind(this);
		this.receivedQuizStatus = this.receivedQuizStatus.bind(this);
		this.setAnswerDetailsVisibility = this.setAnswerDetailsVisibility.bind(this);
		this.prepareToFinish = this.prepareToFinish.bind(this);
		this.setFinishVisibility = this.setFinishVisibility.bind(this);
		this.finishQuiz = this.finishQuiz.bind(this);
		this.updateTimer = this.updateTimer.bind(this);
		this.render = this.render.bind(this);
		this.getRemainingSeconds = this.getRemainingSeconds.bind(this);
		this.verifyQuestion = this.verifyQuestion.bind(this);
		this.nextQuestion = this.nextQuestion.bind(this);
		this.previousQuestion = this.previousQuestion.bind(this);
	}

	shouldComponentUpdate(nextProps, nextState){
		this.previousVisible = this.props.open;
		return true;
	}

	componentDidUpdate(){
		if(this.previousVisible !== this.props.open && this.props.open){
			this.setState(function(state){
				state.journeyId = null;
				state.trailId = null;
				state.quizId = null;
				state.questions = null;
				state.questionMap = null;
				state.activeQuestionIndex = 0;
				state.moveToQuestionIndex = 0;
				state.type = null;
				state.timeLimit = null;
				state.remainingTime = null;
				state.timerDisplay = null;
				state.endTime = null;
				state.isLoading = true;
				state.isProcessing = false;
				state.isShowingAnswerDetails = false;
				state.isFinishPending = false;
				state.hasPendingQuestions = true;
				state.hasError = false;
				return state;
			});
			RequestManager.requestAPI(
				'/student/quiz/start',
				{
                    journeyId: this.props.journeyId,
                    trailId: this.props.trailId
				},
				this.receivedQuizData
			);
		}

	}

	receivedQuizData(hasError, receivedData){
		if(!hasError && receivedData.questionIdList !== null && receivedData.questionList !== null){
			var questionMap = {};
			for(var i=0; i<receivedData.questionList.length; i++){
				var q = receivedData.questionList[i];
				var questionId = ''+q.id;
				questionMap[questionId] = q;
			}
			var activeQuestionIndex = 0;
			var hasPendingQuestions = false;
			for(i=0; i<receivedData.questionIdList.length; i++){
				questionId = receivedData.questionIdList[i]; 
				if (questionMap[questionId] && questionMap[questionId].status === 'P'){
					hasPendingQuestions = true;
					activeQuestionIndex = i;
					break;
				}
			}
			var endTime = null;
			if (receivedData.type === 'E' && receivedData.timeLimit !== null && receivedData.timeLimit > 0 && receivedData.remainingTime !== null){
				var now = new Date();
				endTime = new Date(now.getTime() + (receivedData.remainingTime * 60 * 1000));
			}
			this.setState(function(state){
				state.journeyId = receivedData.journeyId;
				state.trailId = receivedData.trailId;
				state.quizId = receivedData.quizId;
				state.type = receivedData.type;
				state.timeLimit = receivedData.timeLimit;
				state.remainingTime = receivedData.remainingTime;
				state.timerDisplay = null;
				state.endTime = endTime;
				state.questions = receivedData.questionIdList;
				state.questionMap = questionMap;
				state.activeQuestionIndex = activeQuestionIndex;
				state.isLoading = false;
				state.isProcessing = false;
				state.isShowingAnswerDetails = false;
				state.hasPendingQuestions = hasPendingQuestions;
				state.isFinishPending = false;
				return state;
			});
			if (endTime !== null){
				this.updateTimer();
			}
		}
	}

	getRemainingSeconds(){
		var now = new Date();
		if (this.state.timeLimit !== null
				&& this.state.timeLimit > 0
					&& this.state.endTime !== null
						&& this.state.endTime > now)
		{
			return Math.round((this.state.endTime.getTime() - now.getTime()) / 1000);
		}
		return 0;
	};

	updateTimer(){
		if (this.timerDisplaySetTimeout){
			clearTimeout(this.timerDisplaySetTimeout);
			this.timerDisplaySetTimeout = null;
		}
		var seconds = this.getRemainingSeconds();
		var remainingTime = Math.ceil( seconds / 60 );
		var hours = Math.floor(seconds / 3600);
		seconds -= (hours * 3600);
		var minutes = Math.floor(seconds / 60);
		seconds -= (minutes * 60);
		var timerDisplay = ('0'+hours).substr(-2)+':'+('0'+minutes).substr(-2)+':'+('0'+seconds).substr(-2);
		this.setState(function(state){
			state.timerDisplay = timerDisplay;
			state.remainingTime = remainingTime;
			return state;
		});
		if (this.state.remainingTime <= 0){
			this.finishQuiz();			
		}else{
			this.timerDisplaySetTimeout = setTimeout(this.updateTimer,1000);
		}
	}

	sendAnswer(action){
		if (this.state.isProcessing 
				|| this.state.activeQuestionIndex < 0
					|| this.state.activeQuestionIndex >= this.state.questions.length){
			return;
		}
		var questionId = this.state.questions[this.state.activeQuestionIndex];
		var question = this.state.questionMap[questionId];
		var sendParameters = {
			action: action,
			journeyId: this.state.journeyId,
			trailId: this.state.trailId,
			quizId: this.state.quizId,
			questionId: question.id,
			answers: question.answers,
			remainingTime: Math.round( this.getRemainingSeconds() / 60 )
		};
		if (question.correction !== null){
			if (action !== 'finish'){
				this.setState(function(state){
					state.activeQuestionIndex = state.moveToQuestionIndex;
					return state;
				});	
				return;
			}
			sendParameters.questionId = null;
			sendParameters.answers = null;
		}
		this.setState(function(state){
			state.isProcessing = true;
			return state;
		});
		RequestManager.requestAPI(
			'/student/quiz/send/answer',
			sendParameters,
			this.receivedQuizStatus
		);
	}

	receivedQuizStatus(hasError, receivedData){
		if(hasError){
			return;
		}
		if (receivedData.isComplete){
			if (this.timerDisplaySetTimeout){
				clearTimeout(this.timerDisplaySetTimeout);
				this.timerDisplaySetTimeout = null;
			}	
			this.props.onDone();
			return;
		}
		this.setState(function(state){
			if (receivedData.questions !== null){
				for (var i=0; i<receivedData.questions.length; i++){
					var q = receivedData.questions[i];
					var questionId = q.id;
					if (state.questionMap[questionId]){
						state.questionMap[questionId].status = (q.isCorrect ? 'C' : 'I');
						state.questionMap[questionId].correction = q.correction;
						if (q.answerDetails !== null){
							state.questionMap[questionId].answerDetails = q.answerDetails;
						}
					}
				}
			}
			state.remainingTime = receivedData.remainingTime;
			state.activeQuestionIndex = state.moveToQuestionIndex;
			state.isProcessing = false;
			return state;
		});
	}

	finishQuiz(){
		this.setState(function(state){
			state.moveToQuestionIndex = state.activeQuestionIndex;
			return state;
		});
		this.sendAnswer('finish');
	}

	prepareToFinish(){
		this.setFinishVisibility(true);
		this.sendAnswer('save');
	}

	verifyQuestion(){
		this.setState(function(state){
			state.moveToQuestionIndex = state.activeQuestionIndex;
			return state;
		});
		this.sendAnswer('verify');
	}

	moveToQuestion(index){
		this.setState(function(state){
			state.moveToQuestionIndex = index;
			state.isFinishPending = false;
			return state;
		});
		this.sendAnswer('save');
	}

	nextQuestion(){
		var moveToQuestionIndex = this.state.activeQuestionIndex+1;
		if (moveToQuestionIndex >= this.state.questions.length){
			if (this.state.type === 'A'){
				this.sendAnswer('finish');
				return;
			}
			moveToQuestionIndex = 0;
		}
		this.setState(function(state){
			state.moveToQuestionIndex = moveToQuestionIndex;
			return state;
		});
		this.sendAnswer('save');
	}

	previousQuestion(){
		var moveToQuestionIndex = this.state.activeQuestionIndex-1;
		if (moveToQuestionIndex < 0){
			moveToQuestionIndex = this.state.questions.length-1;
		}
		this.setState(function(state){
			state.moveToQuestionIndex = moveToQuestionIndex;
			return state;
		});
		this.sendAnswer('save');
	}

	answerClickHandler(questionIndex, sequence){
		var questionId = this.state.questions[questionIndex];
		var question = this.state.questionMap[questionId];
		if (question.correction !== null || question.status === 'B'){
			return;
		}
		var answers = question.answers;
		if (answers === null){
			answers = [];
			for(var x=0; x<question.sequences.length; x++){
				answers.push( false );
			}
		}
		var activateAnswerIndex = sequence,
			deactivateAnswerIndex = null;
		if (question.type === 'MM'){
			if (answers[sequence]){
				activateAnswerIndex = null;
				deactivateAnswerIndex = sequence;
			}
		}else{
			var oldActiveIndex = null;
			for (var i=0; i<answers.length; i++){
				if (answers[i]){
					oldActiveIndex = i;
					break;
				}
			}
			if (oldActiveIndex === sequence){
				activateAnswerIndex = null;
				deactivateAnswerIndex = sequence;
			}else if (oldActiveIndex !== null){
				deactivateAnswerIndex = oldActiveIndex;
			}
		}
		if (activateAnswerIndex !== null){
			answers[activateAnswerIndex] = true;
		}
		if (deactivateAnswerIndex !== null){
			answers[deactivateAnswerIndex] = false;
		}
		var selectedOptions = 0;
		for(var z=0; z<question.sequences.length; z++){
			if (answers[z]) selectedOptions++;
		}
		var correctAnswers = (question.type === 'MM' ? question.correctAnswers : 1);
		var questionStatus = (selectedOptions === correctAnswers ? 'A' : 'P');
		var hasPendingQuestions = false;
		if (questionStatus === 'A'){

		}
		for(i=0; i < this.state.questions.length; i++){
			var id = this.state.questions[i];
			var status = (questionId === id ? questionStatus : this.state.questionMap[id].status);
			if (status === 'P'){
				hasPendingQuestions = true;
				break;
			}
		}


		this.setState(function(state){
			state.questionMap[questionId].answers = answers;
			state.questionMap[questionId].status = questionStatus;
			state.hasPendingQuestions = hasPendingQuestions;
			return state;
		});	
		if (this.state.type === 'A'){
			this.answerClickSetTimeout = setTimeout(this.nextQuestion,200);
		}
	}

	setAnswerDetailsVisibility(visible){
		this.setState(function(state){
			state.isShowingAnswerDetails = visible;
			return state;
		});
	}

	setFinishVisibility(visible){
		this.setState(function(state){
			state.isFinishPending = visible;
			return state;
		});
	}

	indexToChar(index){
		return String.fromCharCode(65 + (index));
	}

	render(){
		if (this.state.isLoading){
			return <></>;
		}
		if (this.state.isFinishPending){
			var actionLabel = (this.state.hasPendingQuestions ? 'Deseja realmente encerrar' : 'Podemos concluir');
			var quizTypeLabel;
			if (this.state.type === 'P') quizTypeLabel = actionLabel+' o Exercício?';
			else if (this.state.type === 'A') quizTypeLabel = actionLabel+' o Teste?';
			else if (this.state.type === 'S') quizTypeLabel = actionLabel+' a Pesquisa?';
			else quizTypeLabel = actionLabel+' o Teste?';
			return <Modal open={this.props.open} >
				<Segment.Group>
					<Segment textAlign="center" className="QUIZ_COMPLETE">
						<Header as="h1" color="black">{quizTypeLabel}</Header>
						<Button color='red' onClick={()=>{this.setFinishVisibility(false)}}>
							Não
						</Button>
						<Button color='green' onClick={()=>{this.finishQuiz()}}>
							Sim
						</Button>
						{
							(this.state.type === 'E' || this.state.type === 'P')
							&&	<div className="QUIZ_QUESTION_MAP">
									<Header as="h3" color="black">
										Questões
									</Header>
									{
										this.state.questions.map(function(questionId ,index){
											var question = this.state.questionMap[questionId];
											var className = "QUIZ_QUESTION_STATUS_"+question.status;
											return <Label key={questionId} className={className} onClick={()=>{this.moveToQuestion(index)}}>
												{index+1}
											</Label>
										}, this)
									}							
								</div>
						}
						
					</Segment>
				</Segment.Group>
			</Modal>;
		}
		if (this.state.questions === null
				|| this.state.questionMap === null
					|| this.state.activeQuestionIndex < 0
						|| this.state.activeQuestionIndex >= this.state.questions.length)
		{
			return <></>;
		}
		var questionIndex = this.state.activeQuestionIndex;
		var questionId = this.state.questions[questionIndex];
		var question = this.state.questionMap[questionId];
		var contentMarkdown = '';
		if (!this.state.isShowingAnswerDetails && question.content !== null){
			contentMarkdown = question.content.replaceAll('__','\\_\\_');
		}

		return (
			<Modal open={this.props.open} >
				<Segment.Group>
				{this.state.isShowingAnswerDetails
					?	<>
							<Segment className="QUIZ_HEADER">
								<Button className="QUIZ_ALIGN_RIGHT" onClick={()=>{this.setAnswerDetailsVisibility(false)}}>
									Fechar
								</Button>
								Resposta
							</Segment>
							<Segment className="QUIZ_CONTENT">
								<ReactMarkdown 
									source={question.answerDetails.replaceAll('__','\\_\\_')}
									className='markdown-body MARKDOWN_CONTENT '
									renderers={
										{
											link: props => <a href={props.href} target="_blank" rel="noopener noreferrer">{props.children}</a>,
											image: MDImage,
											code: CodeBlock
										}
									}
								/>
							</Segment>
						</>
					:	<>
						<Segment className="QUIZ_HEADER">
							Questão <strong>{ this.state.activeQuestionIndex+1 }</strong> de { this.state.questions.length } &nbsp; &nbsp;
							{this.state.timerDisplay !== null && 
								<strong className={this.state.remainingTime <= (this.state.timeLimit/10) ? " QUIZ_HIGHLIGHT" : ""}>
									{this.state.timerDisplay}
								</strong>
							}
						</Segment>
						<Segment className="QUIZ_CONTENT">
							<div className="QUIZ_QUESTION_BOX">
								<ReactMarkdown 
									source={contentMarkdown}
									className='markdown-body QUESTION-MARKDOWN'
									renderers={
										{
											link: props => <a href={props.href} target="_blank" rel="noopener noreferrer">{props.children}</a>,
											image: MDImage,
											code: CodeBlock
										}
									}
								/>
								{question.type === 'MM' &&
									<span className={(question.status === 'P' || question.status === 'I' ? 'QUIZ_QUESTION_NOTE':'QUIZ_QUESTION_INFO')}>
										(selecione as {question.correctAnswers} opções corretas) &nbsp;
									</span>
								}
								{question.type === 'RA' && 
									<div className="LINE">
										{question.sequences.map((sequence ,index) => (
											<div 
												key={sequence}
												onClick={()=>{this.answerClickHandler(questionIndex, sequence)}}	
											>
												<span className="QUIZ_LINE">
													{question.options[sequence]}
												</span>
											</div>
										))}
									</div>
								}
								{question.sequences.map(function(sequence ,index){
									var isSelected = (question.answers !== null && question.answers[sequence]);
									var answerStatusClass = (isSelected ? 'QUIZ_ACTIVE' : '');
									if (question.correction !== null){
										var isCorrect = question.correction[sequence];
										if (isCorrect){
											answerStatusClass += ' QUIZ_CORRECT';
										}else if (isSelected){
											answerStatusClass += ' QUIZ_INCORRECT';
										}
										return <div
											key={sequence}
											className={'QUIZ_ANSWER_ROW '+answerStatusClass}
										>
											{   question.type === 'MM'
												?   <Label size="large" className="QUIZ_ANSWER_CHECKBOX">
														{this.indexToChar(index)}
													</Label>
												:	<Label size="large" className="QUIZ_ANSWER_HANDLER" circular>
														{this.indexToChar(index)}
													</Label>
											}
											{question.options[sequence]}
										</div>;
									}else{
										if (isSelected && question.status === 'B'){
											answerStatusClass = 'QUIZ_BLOCKED';
										}
										if ("BT,BY,RI,RS".indexOf(question.type) >= 0){
												return <div
												key={sequence}
												className={'QUIZ_ANSWER_ROW '+answerStatusClass} 
												onClick={()=>{this.answerClickHandler(questionIndex, sequence)}}
											>
												<Label size="large" className="QUIZ_ANSWER_HANDLER" circular>
													{question.options[sequence]}
												</Label>
											</div>;
										}else if ("MM,MS".indexOf(question.type) >= 0){
											return <div
												key={sequence}
												className={'QUIZ_ANSWER_ROW '+answerStatusClass} 
												onClick={()=>{this.answerClickHandler(questionIndex, sequence)}}
											>
												{   question.type === 'MM'
													?   <Label size="large" className="QUIZ_ANSWER_CHECKBOX">
															{this.indexToChar(index)}
														</Label>
													:	<Label size="large" className="QUIZ_ANSWER_HANDLER" circular>
															{this.indexToChar(index)}
														</Label>
												}
												{question.options[sequence]}
											</div>;
										}
										return <></>;
									}
								}, this)}
							</div>
						</Segment>
						{this.state.type !== 'A' &&	
							<Segment>
								{this.state.type !== 'E' &&
								(this.state.type === 'P' &&	question.correction !== null && question.answerDetails !== null
									?	<Button onClick={()=>{this.setAnswerDetailsVisibility(true)}}>Resposta</Button>
									:	<Button disabled={(question.status !== 'A' && question.status !== 'B') || question.correction !== null} onClick={()=>{this.verifyQuestion()}}>Verificar</Button>)
								}
								<Button 
									disabled={this.state.moveToQuestionIndex === 0} 
									onClick={()=>{this.previousQuestion()}}
								>
									Anterior
								</Button>
								<Button disabled={this.state.moveToQuestionIndex+1 === this.state.questions.length} onClick={()=>{this.nextQuestion()}}>
									Próxima
								</Button>
								<Button disabled={this.state.hasPendingQuestions && (this.state.type === 'A' || this.state.type === 'S')} className="QUIZ_ALIGN_RIGHT" onClick={()=>{this.prepareToFinish()}}>
									Finalizar o Teste
								</Button>
							</Segment>
						}
					</>
				}
				</Segment.Group>
			</Modal>
		);
	}
}

function MDImage(props) {
	return <img {...props}  alt="" style={{maxWidth: '100%'}} />
}

export default TrailQuiz;