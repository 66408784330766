import React from 'react';
import { Chart, registerables } from 'chart.js';
import './Chart.css'
Chart.register(...registerables);

class Radar extends React.Component {
    state = {
        firstName: this.props.firstName,
        data: this.props.data
    }

    componentDidMount(){
        const ctx = document.getElementById('myChart').getContext('2d');

        new Chart(ctx, {
        type: 'radar',
        data: this.state.data,
            options: {
                scales: {
                    r: {
                        ticks: {
                            precision: 0
                        },
                        suggestedMin: 0,
                        suggestedMax: 5,
                        beginAtZero: true
                    }
                },
                responsive: false,
                elements: {
                line: {
                    borderWidth: 3
                }
                },
                plugins: {
                    legend: {
                        display: false
                    }
                }
            }
        });
    }

    render(){
        return (
            <div className="CONTAINER-PERSONALITY">
                <canvas id="myChart" width="550" height="550" />
            </div>
        )
    }
}

export default Radar;