import React from 'react';
import 'animate.css';
import './ProjectView.css';
import 'semantic-ui-css/semantic.min.css';
import {
	Modal,
	Button,
	Segment,
	Image
} from 'semantic-ui-react';

import ReactMarkdown from 'react-markdown';
import CodeBlock from "./CodeBlock";

import RequestManager from './RequestManager';

class ProjectView extends React.Component {

	state = {
        projectId : null,
        projectTitle : '',
		projectContent : '',
		isOpen: this.props.open,
		isLoading: true,
		hasError: false
	};

	previousVisible = null;

	constructor(state){
		super(state);
		this.receivedProjectData = this.receivedProjectData.bind(this);

	}

	shouldComponentUpdate(nextProps, nextState){
		this.previousVisible = this.props.open;
		return true;
	}

	componentDidUpdate(){
		if(this.previousVisible !== this.props.open && this.props.open){
			RequestManager.requestAPI(
				'/student/project/view',
				{
					id: this.props.projectId
				},
				this.receivedProjectData
			);
		}

	}

	receivedProjectData(hasError, receivedData){
		if(!hasError){
            this.setState(function(state){
                state.projectId = receivedData.id;
                state.projectTitle = receivedData.title;
                state.projectContent = this.buildProjectContent(receivedData.topics);
                return state;
            });
		}
	}

	buildProjectContent(topics){
		var projectMarkdown = '';
		for(var i = 0 ; i < topics.length ; i++){
			projectMarkdown += '## ' + topics[i].title + '\n\n';
			projectMarkdown += topics[i].content + '\n\n';
		}
		projectMarkdown += '\n\n';
		return projectMarkdown;
    }

	closePopUP(){
		this.setState(function(state){
			state.isLoading = false;
			state.hasError = false;
			return state;
		});
	}

	setLoadingState(isLoading){
		this.setState(function(state){
			state.isLoading = isLoading;
			return state;
		});
	}

	render(){
		return <Modal open={this.props.open} >
			<Segment.Group className="PROJECT_VIEWER">
				<Segment className="PROJECT_HEADER">
					<Button className="PROJECT_BUTTON" onClick={()=>{this.props.onCancelClick(); this.closePopUP()}}>
						Fechar
					</Button>
					{this.state.projectTitle}
				</Segment>
				<Segment className="PROJECT_CONTAINER">
					<ReactMarkdown 
						source={this.state.projectContent} 
						className='markdown-body'
						renderers={
							{
								link: props => <a href={props.href} target="_blank" rel="noopener noreferrer">{props.children}</a>,
								image: Image,
								code: CodeBlock
							}
						}
					/>
				</Segment>
				<Segment textAlign="right">
					<Button onClick={()=>{this.props.onCancelClick(); this.closePopUP()}}>
						Fechar
					</Button>
				</Segment>
			</Segment.Group>
		</Modal>;
	}
}

export default ProjectView;