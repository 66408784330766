import React from 'react';
import 'animate.css';
import 'semantic-ui-css/semantic.min.css';
import Highlight from 'react-highlight'
import '../node_modules/highlight.js/styles/darcula.css'
import './Journey.css';
import './Markdown.css';
import {
	Menu, 
	Header,
	Button,
	Accordion, 
	Image,
	Label,
	Icon,
	Segment,
	Dimmer,
	Loader,
	Dropdown,
	Breadcrumb,
	Comment,
	TextArea,
	Modal,
	Grid,
	Popup,
	Confirm
} from 'semantic-ui-react';
import {
	Link
} from 'react-router-dom';

import eIcon from './media/eLogo.png';
import profileIcon from './media/profile.png';

import Vimeo from '@u-wave/react-vimeo';
import ReactMarkdown from 'react-markdown';
import CodeBlock from "./CodeBlock";
import DatetimeManager from './DatetimeManager';
import RequestManager from './RequestManager';
import ProjectView from './ProjectView';
import EditProfile from './EditProfile';
import Profile from './Profile';
import ChangePassword from './ChangePassword';
import WorkspaceConnect from './WorkspaceConnect';
import FaqView from './FaqView';
import TrailQuiz from './TrailQuiz';


class Journey extends React.Component {
	state = {
		isLoading: true,
		isInitialized: false,
		menuActive: false,
		userData: {},
		workspaceData: {},
		programId: null,
		programTitle: '',
		journeyId: parseInt(this.props.match.params.id),
		journeyTitle: '',
		journeyViewMode: 'V',
		journeyIsComplete: false,
		journeyScore: null,
		hasMentorAssigned: false,
		showMessenger: false,
		messengerData: null,
		messengerTrailIndex: -1,
		messageInputActive: false,
		messengerInput: '',
		isSendingMessage: false,
		isMessageDeletePending: false,
		trails: [],
		activeTrailIndex: -1,
		activeTrailData: null,
		activeStepIndex: null,
		activeContentData: null,
		displayVideoClosing: false,
		isChallengeComplete: false,
		isChallengeRetake: false,
		isAnswerSubmitted: false,
		isActivitySubmitted: false,
		isProjectModalOpen: false,
		isEditProfileModalOpen: false,
		isChangePasswordModalOpen: false,
		isWorkspaceConnectModalOpen: false,
		isFaqModalOpen: false,
		isTrailQuizModalOpen: false,
		isQuizLoaded: false,
		quizData: null,
		isChallengeLoaded: false,
		challengeData: null,
		hasFailedQuestions: null,
		hasFailedActivity: null,
		activities: [],
		activityIsCorrect: [],
		activityMessages: [],
		questions: [],
		answerIsCorrect: [],
		workspaceType : null,
		workspaceUserId : null,
		workspaceInstanceId : null,
		workspaceOption: "O",
		faqStepIndex: -1,
    	activeFaqIndex: -1,
		open: false,
		openModalReport: false,
		isMentoring: false,
		inspectorData: [],
		workspace: [],
		inspectorLoading: false,
		inspectorApexClassList: [],
		inspectorObjectList: [],
		inspectorMetaDataType: '',
		inspectorMetaDataName: '',
		inspectorContent: '',
		isViewProfileModalOpen: false
	};

	activeVideoStepIndex = -1;
	videoAutoplayOn = false;
	videoNextStepTimeout = null;
	elemContentScroll = null;
	titleRefs = [];

	constructor(state){
		super(state);
		this.reload = this.reload.bind(this);
		this.loadJourneyData = this.loadJourneyData.bind(this);
		this.receivedJourneyData = this.receivedJourneyData.bind(this);
		this.loadTrailData = this.loadTrailData.bind(this);
		this.receivedTrailData = this.receivedTrailData.bind(this);
		this.loadChallengeData = this.loadChallengeData.bind(this);
		this.receivedChallengeData = this.receivedChallengeData.bind(this);
		this.loadQuizData = this.loadQuizData.bind(this);
		this.receivedQuizData = this.receivedQuizData.bind(this);
		this.showMessenger = this.showMessenger.bind(this);
		this.closeMessenger = this.closeMessenger.bind(this);
		this.sendMessage = this.sendMessage.bind(this);
		this.onSentMessage = this.onSentMessage.bind(this);
		this.loadMessages = this.loadMessages.bind(this);
		this.receivedMessages = this.receivedMessages.bind(this);
		this.showDeleteMessageConfirmation = this.showDeleteMessageConfirmation.bind(this);
		this.deleteLastMessage = this.deleteLastMessage.bind(this);
		this.onDeletedMessage = this.onDeletedMessage.bind(this);
		this.messengerMenuClickHandler = this.messengerMenuClickHandler.bind(this);
		this.trailMenuClickHandler = this.trailMenuClickHandler.bind(this);
		this.stepMenuClickHandler = this.stepMenuClickHandler.bind(this);
		this.launchWorkspace = this.launchWorkspace.bind(this);
		this.onEndVideo = this.onEndVideo.bind(this);
		this.onEndInlineVideo = this.onEndInlineVideo.bind(this);
		this.loadStepData = this.loadStepData.bind(this);
		this.onNextButton = this.onNextButton.bind(this);
		this.setModalWorkspaceConnect = this.setModalWorkspaceConnect.bind(this);
		this.videoNextStepTimeoutHandler = this.videoNextStepTimeoutHandler.bind(this);
		this.retakeChallenge = this.retakeChallenge.bind(this);
		this.playInlineVideo = this.playInlineVideo.bind(this);
		this.elemContentScroll = React.createRef();
		this.answerClickHandler = this.answerClickHandler.bind(this);
		this.submitStepQuiz = this.submitStepQuiz.bind(this);
		this.submitTrailQuiz = this.submitTrailQuiz.bind(this);
		this.receivedChallengeQuizData = this.receivedChallengeQuizData.bind(this);
		this.getWorkSpaceOptions = this.getWorkSpaceOptions.bind(this);
		this.onChallengeEvaluationFinish = this.onChallengeEvaluationFinish.bind(this);
		this.onWorkspaceCreateSession = this.onWorkspaceCreateSession.bind(this);
		this.menuMobileClickHandler = this.menuMobileClickHandler.bind(this);
		this.messengerScrollCanvas = this.messengerScrollCanvas.bind(this);
		this.faqClickHandler = this.faqClickHandler.bind(this);
		this.onDoneTrailQuizModal = this.onDoneTrailQuizModal.bind(this);
		this.openOrClose = this.openOrClose.bind(this);
		this.openModalReport = this.openModalReport.bind(this);
		this.loadInspector = this.loadInspector.bind(this);
		this.receivedInspectorData = this.receivedInspectorData.bind(this);
		this.loadList = this.loadList.bind(this);
		this.receivedLoadList = this.receivedLoadList.bind(this);
		this.setModalViewProfileVisible = this.setModalViewProfileVisible.bind(this);
	}

	componentDidMount(){
		var journeyViewMode = localStorage.getItem('journey-viewmode');
		if( journeyViewMode !== null ){
			this.setState(function(state){
				state.journeyViewMode = journeyViewMode;
			});
		}
		RequestManager.requestAPI(
			'/student/loadjourneydata',
			{
				journeyId: this.state.journeyId
			},
			this.receivedJourneyData
		);

	}

	reload(){
		this.setState(function(state){
			state.isInitialized = false;
			state.isLoading = true;
		});
		this.loadJourneyData();
	}

	loadJourneyData(){
		RequestManager.requestAPI(
			'/student/loadjourneydata',
			{
				journeyId: this.state.journeyId
			},
			this.receivedJourneyData
		);
	}

	loadTrailData(trailId){
		var sendData = {
			journeyId: this.state.journeyId,
			trailId: trailId
		};
		RequestManager.requestAPI(
			'/student/loadtraildata',
			sendData,
			this.receivedTrailData
		);
	}

	loadList(){
		var sendData = {
			journeyId: this.state.journeyId,
			type: this.state.inspectorMetaDataType,
      name: this.state.inspectorMetaDataName
		};

    this.setState((state)=> {
      state.inspectorLoading = true
      return state
    })

		RequestManager.requestAPI(
			'/student/workspace/get-metadata',
			sendData,
			this.receivedLoadList
		);
	}

	loadStepData(stepIndex, displayVideoClosing){
		if (this.state.journeyViewMode !== 'V'
				|| this.state.activeTrailData === null
					|| stepIndex < 0
						|| stepIndex >= this.state.activeTrailData.steps.length){
			return;
		}
		var trail = this.state.activeTrailData,
			step = trail.steps[stepIndex];
		var	answerIsCorrect = [];
		if (step.questions !== null){
			for(var i=0; i < step.questions.length; i++){
				answerIsCorrect.push( null );
			}
		}
		this.setState(function(state){
			state.displayVideoClosing = displayVideoClosing;
			state.isChallengeComplete = false;
			state.hasFailedQuestions = false;
			state.questions = step.questions;
			state.answerIsCorrect = answerIsCorrect;
			state.activeStepIndex = stepIndex;
			state.isLoading = false;
			state.menuActive = false;
			return state;
		});
	}

	loadStepChallenge(){
		if (this.state.journeyViewMode !== 'V'
				|| this.state.activeTrailData === null
					|| this.state.activeStepIndex < 0
						|| this.state.activeStepIndex >= this.state.activeTrailData.steps.length){
			return;
		}
		var trail = this.state.activeTrailData,
			stepIndex = this.state.activeStepIndex,
			step = trail.steps[stepIndex],
			answerIsCorrect = [];
		if (step.questions !== null){
			for(var i=0; i < step.questions.length; i++){
				answerIsCorrect.push( null );
			}
		}	
		this.setState(function(state){
			state.isChallengeComplete = false;
			state.hasFailedQuestions = false;
			state.questions = step.questions;
			state.answerIsCorrect = answerIsCorrect;
			return state;
		});
	}

	loadChallengeData(trailId, retake){
		var sendData = {
			trailId: trailId
		};
		if (retake === true){
			sendData.retake = true;
		}
		RequestManager.requestAPI(
			'/student/loadchallengedata',
			sendData,
			this.receivedChallengeData
		);
	}

	loadQuizData(trailId){
		var sendData = {
			trailId: trailId
		};
		RequestManager.requestAPI(
			'/student/loadquizdata',
			sendData,
			this.receivedQuizData
		);
	}

	loadInspector(trailId){
		RequestManager.requestAPI(
			'/student/challenge/inspector',
			{
				journeyId: this.state.journeyId,
				trailId
			},
			this.receivedInspectorData
		);
	}

	onDoneTrailQuizModal(){
		var trailId = this.state.activeTrailData.id;
		this.setState(function(state){
			state.isTrailQuizModalOpen = false;
			state.activeTrailData = null;
			state.activeStepIndex = -1;
			return state;
		});
		this.loadTrailData(trailId);
	}

	receivedJourneyData(hasError, receivedData){
		if (this.state.journeyViewMode==='V'){
			this.videoAutoplayOn = true;
		}
		if(!hasError && receivedData.trails != null && receivedData.trails.length > 0){
			var activeTrailIndex = -1;
			if (!receivedData.isComplete){
				activeTrailIndex = this.searchForActiveTrail(receivedData.trails);
			}
			this.setState(function(state){
				state.userData = receivedData.userData;
				state.hasMentorAssigned = (receivedData.userData.mentorId !== null && receivedData.userData.mentorId > 0);
				state.workspaceData = receivedData.workspaceData;
				state.workspaceOption = (receivedData.workspaceData.instanceURL !== null ? "A" : "O");
				state.programId = receivedData.programId;
				state.programTitle = receivedData.programTitle;
				state.journeyId = receivedData.journeyId;
				state.journeyTitle = receivedData.journeyTitle;
				state.journeyIsComplete = receivedData.isComplete;
				state.journeyScore = receivedData.score;
				state.trails = receivedData.trails;
				state.activeTrailIndex = activeTrailIndex;
				state.activeStepIndex = -1;
				state.isInitialized = true;
				state.isMentoring = receivedData.isMentoring;
				return state;
			});
			if (activeTrailIndex >= 0 && activeTrailIndex < receivedData.trails.length){
				var activeTrail = receivedData.trails[activeTrailIndex];
				this.loadTrailData(activeTrail.id);	
			}
		}else{
			RequestManager.goBackToLogin();
		}
	}

  	receivedLoadList(hasError, receivedData){
		if(!hasError){
      const data = JSON.parse(receivedData.metadata)
      this.setState((state) => {
        state.inspectorContent = receivedData.type === 'ApexClassList' 
        ? data.Body
        : JSON.stringify(data, null, 2)
        state.inspectorLoading = false
        return state
      })
		}
	}

	receivedTrailData(hasError, receivedData){
		if(hasError){
			RequestManager.goBackToLogin();
		}
		var activeStepIndex;
		if (receivedData.isComplete){
			activeStepIndex = receivedData.steps.length + 1;
		}else{
			activeStepIndex = this.searchForActiveStep(receivedData.steps);
		}
		if (activeStepIndex !== this.state.activeStepIndex){
			for(var i=0; i < receivedData.steps.length; i++){
				receivedData.steps[i].showInlineVideo = false;
			}
			if (this.state.journeyViewMode === 'V'
					&& activeStepIndex >= 0
						&& activeStepIndex < receivedData.steps.length)
			{
				this.setState(function(state){
					if (state.activeTrailIndex >= 0 && state.activeTrailIndex < state.trails.length){
						state.trails[state.activeTrailIndex].isUnlocked = true;
					}
					state.activeTrailData = receivedData;
					state.activeStepIndex = activeStepIndex;
					state.isChallengeLoaded = false;
					state.isChallengeRetake = false;
					state.isQuizLoaded = false;
					return state;
				});
				this.loadStepData(activeStepIndex, false);
			}else{
				this.setState(function(state){
					if (state.activeTrailIndex >= 0 && state.activeTrailIndex < state.trails.length){
						state.trails[state.activeTrailIndex].isUnlocked = true;
					}
					state.activeTrailData = receivedData;
					state.activeStepIndex = activeStepIndex;
					state.isLoading = false;
					state.isChallengeLoaded = false;
					state.isChallengeRetake = false;					
					state.isQuizLoaded = false;
					return state;
				});
			}
		}
		if (this.state.hasMentorAssigned && receivedData.hasPendingMessages){
			this.showMessenger();
		}
	}

	receivedChallengeData(hasError, receivedData){
		if(hasError){
			RequestManager.goBackToLogin();
		}
		var activityIsCorrect = [],
			activityMessages = [],
			answerIsCorrect = [];
		if (receivedData.activities !== null){
			for(var i=0; i < receivedData.activities.length; i++){
				activityIsCorrect.push( null );
				activityMessages.push( null );
			}
		}
		if (receivedData.questions !== null){
			for(i=0; i < receivedData.questions.length; i++){
				answerIsCorrect.push( null );
			}
		}
		this.setState(function(state){
			if (receivedData.isComplete && !state.activeTrailData.isComplete){
				state.activeTrailData.isComplete = true;
			}
			state.challengeData = receivedData;
			state.activities = receivedData.activities;
			state.activityIsCorrect = activityIsCorrect;
			state.activityMessages = activityMessages;
			state.questions = receivedData.questions;
			state.answerIsCorrect = answerIsCorrect;
			state.isChallengeRetake = receivedData.isRetake;
			state.isChallengeComplete = false;
			state.isChallengeLoaded = true;
			state.hasFailedQuestions = false;
			state.hasFailedActivity = false;
			return state;
		});
	}

	receivedQuizData(hasError, receivedData){
		if(hasError){
			RequestManager.goBackToLogin();
		}
		this.setState(function(state){
			state.quizData = receivedData;
			state.isQuizLoaded = true;
			return state;
		});
	}

	receivedInspectorData(hasError, receivedData){
		if(!hasError){
			const arrayTempApexClassList = []
			const arrayTempObjectList = []

			this.setState((state) => {
				state.inspectorData = receivedData.challenges.map(
					(challenge) => { 
						challenge.evaluations.map((evaluation) => {
							evaluation.content = JSON.parse(evaluation.content)
							evaluation.activityMetadata = JSON.parse(evaluation.activityMetadata)
							return evaluation
						})
						return challenge
					}
				)
				state.workspace = receivedData.workspace
        		state.workspace = {...state.workspace, 
					cacheData: state.workspace.cacheData.map((cache) => {
						cache.metadata = JSON.parse(cache.metadata)
						return cache
					})
        		}
				state.workspace.cacheData
				.filter((fill) => fill.type === 'ApexClassList')
				.map((cache) => {
					cache.metadata.classes.map((classes, index) => {
					arrayTempApexClassList.push({  
						key: index, 
						value: classes.id, 
						text: classes.name 
					})
					return {}
					})
					return {}
				})
				state.workspace.cacheData
				.filter((fill) => fill.type === 'ObjectList')
				.map((cache) => {
					cache.metadata.objects.map((objects, index) => {
					arrayTempObjectList.push({  
						key: index, 
						value: objects.name, 
						text: objects.name 
					})
					return {}
					})
					return {}
				})
				const idCompare = arrayTempApexClassList.find(arr => arr.text === 'PragmaTestEngine')?.value

				state.inspectorApexClassList = arrayTempApexClassList.filter((arr) => arr.value !== idCompare)
				state.inspectorObjectList = arrayTempObjectList
				state.inspectorLoading = false
				return state
			})

			this.openModalReport()
		}
	}

	sendMessage(){
		if (this.state.messengerInput === null
			|| this.state.messengerInput.length < 10
				|| this.state.messengerData === null
					|| this.state.messengerData.trailId === null)
		{
			return;
		}
		var sendData = {
			journeyId: this.state.journeyId,
			trailId: this.state.messengerData.trailId,
			message: this.state.messengerInput
		};
		RequestManager.requestAPI(
			'/student/messenger/sendmessage',
			sendData,
			this.onSentMessage
		);
		this.setState(function(state){
			state.isSendingMessage = true;
			state.messageInputActive = false;
			state.messengerInput = '';
			return state;
		});
	}

	onSentMessage(hasError, receivedData){
		if(!hasError){
			if (this.state.showMessenger){
				this.loadMessages(this.state.messengerTrailIndex);
			}
		}
	}

	showDeleteMessageConfirmation(visibility){
		this.setState(function(state){
			state.isMessageDeletePending = visibility;
			return state;
		});
	}

	deleteLastMessage(){
		if (this.state.messengerData === null
				|| this.state.messengerData.trailId === null
					|| this.state.messengerData.trailMessages === null
						|| this.state.messengerData.trailMessages.length <= 0)
		{
			return;
		}
		var lastMessageIndex = this.state.messengerData.trailMessages.length - 1;
		if (this.state.messengerData.trailMessages[lastMessageIndex].type === 'Q'){
			var sendData = {
				journeyId: this.state.journeyId,
				trailId: this.state.messengerData.trailId,
				messageId: this.state.messengerData.trailMessages[lastMessageIndex].id
			};
			RequestManager.requestAPI(
				'/student/messenger/deletemessage',
				sendData,
				this.onDeletedMessage
			);
			this.setState(function(state){
				state.isMessageDeletePending = false;
				return state;
			});
		}
	}

	onDeletedMessage(hasError, receivedData){
		if(!hasError){
			if (this.state.showMessenger){
				this.loadMessages(this.state.messengerTrailIndex);
			}
		}
	}

	loadMessages(trailIndex){
		var trailId = null;
		if (trailIndex >= 0 && trailIndex < this.state.trails.length){
			var trail = this.state.trails[trailIndex];
			if (trail.isUnlocked){
				trailId = trail.id;
			}
		}
		if (trailId != null){
			var sendData = {
				journeyId: this.state.journeyId,
				trailId: trailId
			};
			RequestManager.requestAPI(
				'/student/messenger/loadmessages',
				sendData,
				this.receivedMessages
			);
		}
	}

	receivedMessages(hasError, receivedData){
		if(hasError){
			//RequestManager.goBackToLogin();
			return;
		}
		this.setState(function(state){
			state.isSendingMessage = false;
			state.messengerData = receivedData;
			return state;
		});
		setTimeout(
			this.messengerScrollCanvas,
			100
		);
	}

	onWorkspaceCreateSession(data){
		if (data.workspace != null){
			this.setState(function(state){
				state.workspaceData = data.workspace;
				state.workspaceOption = "A";
				return state;
			});
		}
	}

	onChallengeEvaluationFinish(data){
		if (this.state.activities != null 
				&& this.state.activities.length > 0 
					&& data !== null
						&& data.challenges != null
							&& data.challenges.length > 0)
		{
			var activityIsCorrect = [],
				activityMessages = [],
				hasActivityPending = false;
			for(var i=0; i<this.state.activities.length; i++){
				var isCorrect = null,
					message = null;
				if (this.state.activities[i].id != null){
					for(var y=0; y<data.challenges.length; y++){
						if (this.state.activities[i].id === data.challenges[y].id){
							if (data.challenges[y].hasErrors === true){
								isCorrect = false;
								message = data.challenges[y].message;
								if (data.challenges[y].details != null){
									message += '\n\n' + data.challenges[y].details;
								}
							}else if (data.challenges[y].hasErrors === false){
								isCorrect = true;
							}
							break;
						}
					}	
				}
				if (isCorrect !== true) hasActivityPending = true;
				activityIsCorrect.push(isCorrect);
				activityMessages.push(message);
			}
			if (hasActivityPending){
				this.setState(function(state){
					state.activityIsCorrect = activityIsCorrect;
					state.activityMessages = activityMessages;	
					return state;
				});
			}else{
				this.loadChallengeData(this.state.activeTrailData.id);
			}
		}
	}

	searchForActiveTrail(trails){
		var activeTrailIndex = -1;
		for(var i = 0 ; i < trails.length ; i++){
			if (!trails[i].isComplete){
				activeTrailIndex = i;
				break;
			}
		}
		return activeTrailIndex;
	}

	searchForActiveStep(steps){
		var activeStepIndex = steps.length + 1;
		for(var i = 0 ; i < steps.length ; i++){
			if (!steps[i].isViewed){
				activeStepIndex = i;
				break;
			}
		}
		return activeStepIndex;
	}

	messengerMenuClickHandler(trailIndex){
		if (trailIndex !== this.state.messengerTrailIndex){
			this.setState(function(state){
				state.messengerData = null;
				state.messageInputActive = false;
				state.messengerInput = '';
				state.messengerTrailIndex = trailIndex;
				return state;
			});
		}
		this.loadMessages(trailIndex);
	}

	trailMenuClickHandler(index){
		this.videoAutoplayOn = false;
		if(this.state.activeTrailIndex === index && this.state.activeTrailData !== null){
			var activeStepIndex;
			if (this.state.activeTrailData.isComplete){
				activeStepIndex = this.state.activeTrailData.steps.length + 1;
			}else{
				activeStepIndex = this.searchForActiveStep(this.state.activeTrailData.steps);
			}
			this.elemContentScroll.current.scrollTo({
				top: 0,
				left: 0,
				behavior: 'smooth'
			});
			if(this.state.journeyViewMode === 'V'){
				if(this.state.activeStepIndex !== activeStepIndex){
					this.setState(function(state){
						state.activeStepIndex = activeStepIndex;
						state.displayVideoClosing = false;
						state.menuActive = false;
						return state;
					});
				}else if (this.state.isChallengeRetake){
					this.setState(function(state){
						state.isChallengeRetake = false;
						state.menuActive = false;
						return state;
					});
				}
			}
		}else if(index >= 0 && index < this.state.trails.length){
			if (this.state.trails[index].isUnlocked){
				this.setState(function(state){
					state.activeTrailIndex = index;
					state.activeTrailData = null;
					state.activeStepIndex = -1;
					state.displayVideoClosing = false;
					state.isChallengeLoaded = false;
					state.isQuizLoaded = false;
					state.isLoading = true;
					state.menuActive = false;
					return state;
				});
				this.loadTrailData(this.state.trails[index].id);
			}
		}
		if (this.state.showMessenger){
			this.closeMessenger();
		}
	}

	stepMenuClickHandler(index){
		if (this.state.isLoading || this.state.activeTrailData === null){
			return;
		}
		if (this.state.showMessenger){
			this.closeMessenger();
			return;
		}
		if(this.state.journeyViewMode === 'T' || this.state.activeTrailData.type === "C" || this.state.activeTrailData.type === "Q"){
			var ref = this.titleRefs[index];
			if(ref){
				this.elemContentScroll.current.scrollTo({
					top: ref.current.offsetTop - 30,
					left: 0,
					behavior: 'smooth'
				});
			}
		}else if(this.state.journeyViewMode === 'V'){
			this.elemContentScroll.current.scrollTo({
				top: 0,
				left: 0,
				behavior: 'smooth'
			});
			if(this.state.activeStepIndex !== index){
				this.loadStepData(index, false);
			}
		}
		this.setState(function(state){
			state.activeStepIndex = index;
			state.menuActive = false;
			return state;
		});
	}

	onEndInlineVideo(stepIndex){
		if(document.fullscreen){
			closeFullscreen();
		}
		if (this.state.journeyViewMode !== 'T'
				|| this.state.activeTrailData === null
					|| stepIndex >= this.state.activeTrailData.steps.length){
			return;
		}
		this.setState(function(state){
			state.activeTrailData.steps[stepIndex].showInlineVideo = false;
			return state;
		});
	}

	onEndVideo(){
		if(document.fullscreen){
			closeFullscreen();
		}
		if (this.state.journeyViewMode !== 'V'
				|| this.state.activeTrailData === null
					|| this.state.activeStepIndex < 0
						|| this.state.activeStepIndex >= this.state.activeTrailData.steps.length){
			return;
		}
		var trail = this.state.activeTrailData,
			stepIndex = this.state.activeStepIndex,
			step = trail.steps[stepIndex];
		if ((step.activity === null || step.activity.length < 10)
				&& (step.questions === null || step.questions.length < 1))
		{
			this.setState(function(state){
				state.displayVideoClosing = true;
				return state;
			});
			if(this.videoNextStepTimeout !== null){
				clearTimeout(this.videoNextStepTimeout);
				this.videoNextStepTimeout = null;
			}
			this.videoNextStepTimeout = setTimeout(
				this.videoNextStepTimeoutHandler,
				5000
			);
		}else{
			this.loadStepData(stepIndex, true);
		}
	}

	onNextButton(){
		if (this.state.activeTrailData === null){
			return;
		}
		this.elemContentScroll.current.scrollTo({
			top: 0,
			left: 0,
			behavior: 'smooth'
		});
		if (this.state.journeyViewMode === 'T'
				|| this.state.activeStepIndex > this.state.activeTrailData.steps.length)
		{
			var nextTrailIndex = this.state.activeTrailIndex + 1;
			if (nextTrailIndex < this.state.trails.length){
				this.setState(function(state){
					state.activeTrailIndex = nextTrailIndex;
					state.activeTrailData = null;
					state.activeStepIndex = -1;
					state.displayVideoClosing = false;
					state.isChallengeLoaded = false;
					state.isQuizLoaded = false;
					state.isLoading = true;
					return state;
				});
				if (this.state.journeyViewMode === 'V'){
					this.videoAutoplayOn = true;
				}
				if (this.state.trails[nextTrailIndex].isUnlocked){
					this.loadTrailData(this.state.trails[nextTrailIndex].id);
				}else{
					this.loadJourneyData();
				}
			}else{
				this.reload();
			}
		}else if (this.state.journeyViewMode === 'V'){
			if (this.state.activeStepIndex < this.state.activeTrailData.steps.length){
				this.setState(function(state){
					state.activeTrailData.steps[this.state.activeStepIndex].isViewed = true;
					return state;
				});
			}
			this.videoAutoplayOn = true;
			this.nextStep();
		}
	}

	nextStep(){
		if (this.state.journeyViewMode !== 'V'
				|| this.state.activeTrailData === null
					|| this.state.activeStepIndex < 0
						|| this.state.activeStepIndex > this.state.activeTrailData.steps.length){
			return;
		}
		var trail = this.state.activeTrailData,
			stepIndex = this.state.activeStepIndex,
			nextStepIndex = stepIndex+1;

		if (nextStepIndex < trail.steps.length){
			this.loadStepData(nextStepIndex, false);
		}else{
			if (nextStepIndex === trail.steps.length) nextStepIndex++;
			this.setState(function(state){
				if (stepIndex < trail.steps.length){
					state.activeTrailData.steps[stepIndex].isViewed = true;
				}
				state.isChallengeLoaded = false;
				state.displayVideoClosing = false;
				state.activeStepIndex = nextStepIndex;
				state.isQuizLoaded = false;
				return state;
			});	
		}
		if (stepIndex < trail.steps.length){
			var stepId = trail.steps[stepIndex].id;
			RequestManager.requestAPI(
				'/student/step/status',
				{ id: stepId },
				function(hasError, receiveData){}
			);
		}
	}

	videoNextStepTimeoutHandler(){
		if(this.videoNextStepTimeout !== null){
			clearTimeout(this.videoNextStepTimeout);
			this.videoNextStepTimeout = null;
		}
		this.videoAutoplayOn = true;
		this.nextStep();
	}

	retakeChallenge(){
		if (this.state.activeTrailData === null){
			return;
		}
		this.loadChallengeData(this.state.activeTrailData.id, true);
	}

	getMenuItemIconStep(step){
		if (step.isViewed){
			return 'check';
		}
		if (step.hasVideo){
			return 'play circle';
		}
		return 'file';
	}

	formatMinutes(minutes){
		if (minutes != null){
			var hours = 0;
			if (minutes >= 60){
				hours = parseInt(minutes / 60);
				minutes = minutes % 60;
			}
			return (hours > 0 ? hours + ' hr' + (hours > 1 ? 's ':' ') : '') + (minutes > 0 ? minutes + ' min' : '');
			//return '~' + (hours > 0 ? hours+'h' : '') + (minutes > 0 ? minutes+'min' : '');
		}
		return null;
	}

	displayDatetime(timestamp){
		return timestamp != null ? DatetimeManager.formatTimestamp( timestamp ) : '-';
	}

	setModalProjectVisible(isVisible){
		this.setState(function(state){
			state.isProjectModalOpen = isVisible;
			return state;
		});
	}

	setModalFaqVisible(isVisible, stepIndex){
		this.setState(function(state){
			state.isFaqModalOpen = isVisible;
			state.faqStepIndex = stepIndex;
			return state;
		});
	}

	setModalTrailQuizVisible(isVisible){
		this.setState(function(state){
			state.isTrailQuizModalOpen = isVisible;
			return state;
		});
	}

	setModalEditProfileVisible(){
		this.setState(function(state){
			state.isEditProfileModalOpen = !this.state.isEditProfileModalOpen;
			return state;
		});
	}

	setModalViewProfileVisible(){
		this.setState(function(state){
			state.isViewProfileModalOpen = !this.state.isViewProfileModalOpen;
			return state;
		});
	}

	setModalChangePasswordVisible(isVisible){
		this.setState(function(state){
			state.isChangePasswordModalOpen = isVisible;
			return state;
		});
	}

	showMessenger(){
		var trailIndex = this.state.activeTrailIndex;
		this.setState(function(state){
			state.showMessenger = true;
			state.messengerData = null;
			state.messengerTrailIndex = trailIndex;
			return state;
		});
		this.loadMessages(trailIndex);
	}

	closeMessenger(){
		this.setState(function(state){
			state.showMessenger = false;
			state.messengerData = null;
			state.messengerTrailIndex = -1;
			state.activeFaqIndex = -1;
			return state;
		});
	}

	changeViewMode(newViewMode){
		if (this.state.journeyViewMode !== newViewMode){
			localStorage.setItem('journey-viewmode', newViewMode );
			this.setState(function(state){
				state.journeyViewMode = newViewMode;
				state.showMessenger = false;
				state.activeTrailData = null;
				state.activeStepIndex = -1;
				state.displayVideoClosing = false;
				state.isChallengeLoaded = false;
				state.isQuizLoaded = false;
				state.isLoading = true;
				return state;
			});
			this.loadTrailData(this.state.trails[this.state.activeTrailIndex].id);
		}else if (this.state.showMessenger){
			this.setState(function(state){
				state.showMessenger = false;
				return state;
			});
		}
	}

	setModalWorkspaceConnect(isVisible){
		this.setState(function(state){
			state.isWorkspaceConnectModalOpen = isVisible;
			return state;
		});
	}

	logout(){
		RequestManager.logout();
	}

	setLoadingState(isLoading){
		this.setState(function(state){
			state.isLoading = isLoading;
			return state;
		});
	}

	submitStepQuiz(){
		if(this.checkAnswers()){
			var step = this.state.activeTrailData.steps[this.state.activeStepIndex];
			this.sendChallengeAnswers(step.id)
		}
	}

	submitTrailQuiz(){
		if(this.checkAnswers()){
			this.sendChallengeAnswers(null)
		}
	}

	sendChallengeAnswers(stepId){
		var questions = this.state.questions,
			prepareDataToSend = {
				journeyId: this.state.journeyId,
				trailId: this.state.activeTrailData.id,
				activities: [],
				questions: []
			};
		if (stepId !== null && stepId > 0){
			prepareDataToSend.stepId = stepId;
		}
		for(var i = 0 ; i < questions.length ; i++){
			var question = {
				id : questions[i].id,
				answers: []
			};
			for(var j = 0 ; j < questions[i].answers.length ; j++){
				if(questions[i].answers[j].isActive){
					question.answers.push(questions[i].answers[j].sequence);
				}
			}
			prepareDataToSend.questions.push(question);
		}
		this.setState(function(state){
			state.isAnswerSubmitted = true;
			return state;
		});
		RequestManager.requestAPI(
			'/student/challenge/send/answers',
			prepareDataToSend,
			this.receivedChallengeQuizData
		);
	}

	receivedChallengeQuizData(hasError, receivedData){
		if(hasError){
			RequestManager.goBackToLogin();
			return;
		}
		var answerIsCorrect = [],
			hasFailedQuestions = false;
		if (this.state.questions !== null && receivedData.questions !== null){
			var questions = this.state.questions;
			for(var i=0; i < questions.length; i++){
				var isCorrect = null;
				for(var z=0; z < receivedData.questions.length; z++){
					if (questions[i].id === receivedData.questions[z].id){
						isCorrect = receivedData.questions[z].isCorrect;
						break;
					}
				}
				answerIsCorrect.push( isCorrect );
				if (isCorrect === false){
					hasFailedQuestions = true;
				}
			}
		}
		this.setState(function(state){
			state.isAnswerSubmitted = false;
			state.activeTrailData.score = receivedData.score;
			state.hasFailedQuestions = hasFailedQuestions;
			state.answerIsCorrect = answerIsCorrect;
			if (!hasFailedQuestions){
				state.isChallengeRetake = false;
			}
			return state;
		});
		if (this.state.challengeData !== null && !this.state.isChallengeRetake){
			this.setState(function(state){
				state.challengeData.hasActivityPending = receivedData.hasActivityPending;
				state.challengeData.hasQuestionPending = receivedData.hasQuestionPending;
				state.challengeData.isComplete = receivedData.isComplete;
				state.challengeData.score = receivedData.score;
				state.challengeData.attempts = receivedData.attempts;
				return state;
			});
		}
	}

	checkAnswers(){
		var isChallengeComplete = true,
			checkedAnswers = [],
			questions = this.state.questions;
		for(var i = 0 ; i < questions.length ; i++){
			checkedAnswers[i] = 0;
			for(var j = 0 ; j < questions[i].answers.length ; j++){
				if(questions[i].answers[j].isActive){
					checkedAnswers[i]++;
				}
			}
		}
		for(i = 0 ; i < checkedAnswers.length ; i++){
			if(checkedAnswers[i] <= 0){
				isChallengeComplete = false;
			}
		}
		return isChallengeComplete;
	}

	answerClickHandler(questionIndex, answerIndex){
		var question = this.state.questions[questionIndex],
			answers = question.answers,
			activateAnswerIndex = answerIndex,
			deactivateAnswerIndex = null;
		if (question.type === 'M'){
			var oldActiveIndex = null;
			for (var i=0; i<answers.length; i++){
				if (answers[i].isActive){
					oldActiveIndex = i;
					break;
				}
			}
			if (oldActiveIndex === answerIndex){
				activateAnswerIndex = null;
				deactivateAnswerIndex = answerIndex;
			}else if (oldActiveIndex !== null){
				deactivateAnswerIndex = oldActiveIndex;
			}
		}else{
			if (answers[answerIndex].isActive){
				activateAnswerIndex = null;
				deactivateAnswerIndex = answerIndex;
			}
		}
		this.setState(function(state){
			if (activateAnswerIndex !== null){
				state.questions[questionIndex].answers[activateAnswerIndex].isActive = true;
			}
			if (deactivateAnswerIndex !== null){
				state.questions[questionIndex].answers[deactivateAnswerIndex].isActive = false;
			}
			state.answerIsCorrect[questionIndex] = null;
			state.isChallengeComplete = this.checkAnswers();
			return state;
		});
	}

	getQuestionAnswerStatus(questionIndex){
		if (this.state.answerIsCorrect === null
				|| questionIndex >= this.state.answerIsCorrect.length
					|| this.state.answerIsCorrect[questionIndex] === null){
			return '';
		}
		if (this.state.answerIsCorrect[questionIndex] === true){
			return 'CORRECT';
		}
		return 'INCORRECT';
	}

	getWorkSpaceOptions(){
		var workspaceOptions = [];
		if (this.state.workspaceData.instanceURL !== null && this.state.workspaceData.instanceURL > " "){
			var label;
			if (this.state.workspaceData.userEmail !== null && this.state.workspaceData.userEmail > " "){
				label = this.state.workspaceData.userEmail;
			}else{
				label = 'ambiente de projeto';
			}
			workspaceOptions.push(
				{ key: 'A', value: 'A', text: this.state.workspaceData.label+' ('+label+')' }
			);
			if (this.state.workspaceData.openURL !== null && this.state.workspaceData.openURL > " "){
				workspaceOptions.push(
					{ key: 'O', value: 'O', text: 'Conectar em outro ambiente' }
				);
			}
		}else if (this.state.workspaceData.openURL !== null && this.state.workspaceData.openURL > " "){
			workspaceOptions.push(
				{ key: 'O', value: 'O', text: 'Conectar um ambiente '+this.state.workspaceData.label }
			);
		}
		if (this.state.workspaceData.createURL !== null && this.state.workspaceData.createURL > " "){
			workspaceOptions.push(
				{ key: 'N', value: 'N', text: 'Criar um novo ambiente '+this.state.workspaceData.label }
			);
		}
		return workspaceOptions;
	}

	indexToChar(index){
		return String.fromCharCode(65 + (index));
	}

	setWorkspaceOption(value){
		this.setState(function(state){
			state.workspaceOption = value;
			return state;
		});
	}

	menuMobileClickHandler(){
		var previousMenuState = this.state.menuActive;
		this.setState(function(state){
			state.menuActive = !previousMenuState;
			return state;
		});
	}

	playInlineVideo(stepIndex){
		if (this.state.activeTrailData === null || stepIndex >= this.state.activeTrailData.steps.length){
			return;
		}
		this.activeVideoStepIndex = stepIndex;
		this.setState(function(state){
			state.activeTrailData.steps[stepIndex].showInlineVideo = true;
			return state;
		});
		var ref = this.titleRefs[stepIndex];
		if(ref){
			this.elemContentScroll.current.scrollTo({
				top: ref.current.offsetTop - 30,
				left: 0,
				behavior: 'smooth'
			});
		}
	}

	launchWorkspace(){
		if (this.state.workspaceData === null){
			return;
		}
		var url = null;
		if (this.state.workspaceOption === "N"){
			url = this.state.workspaceData.createURL;
		}else if (this.state.workspaceOption === "A" && this.state.workspaceData.instanceURL !== null){
			url = this.state.workspaceData.instanceURL;
		}else{
			RequestManager.requestAPI(
				'/student/workspace/oauth/session',
				{
					journeyId: this.state.journeyId,
					action: "logout"
				},
				this.receivedOAuthLogout
			);
			url = this.state.workspaceData.openURL;
		}
		if (url !== null){
			window.open(url, "WORKSPACE_INSTANCE");
		}
	}

	faqClickHandler(faqIndex) {
			this.setState(function(state){
				state.activeFaqIndex = state.activeFaqIndex === faqIndex ? -1 : faqIndex;
				return state;
			});
	}

	receivedOAuthLogout(){
		return;
	}

	openOrClose(){
		this.setState(state => {
			state.open = !this.state.open 
			return state;
		})
	}

	openModalReport(){
		this.setState((state) => {
			state.openModalReport = !this.state.openModalReport
			return state
		})
	}

	renderTopMenu(){
		var showProject = (
			this.state.activeTrailData !== null
				&& this.state.activeTrailData.projectId !== null
					&& this.state.activeTrailData.projectId > 0
						&& this.state.activeTrailData.type !== 'Q'
		);
		var showMessenger = (
			this.state.hasMentorAssigned
				&& this.state.activeTrailData !== null
		);
		return <Menu fixed="top" inverted borderless size="large" className="MENU_TOP">
			<Menu.Item icon="bars" onClick={this.menuMobileClickHandler} className="MENU_MOBILE_BUTTON" />
			<Menu.Item className="MENU_BRAND">
				<Link to="/" ><Image src={eIcon} /></Link>
			</Menu.Item>
			<Menu.Item>
				<div className="MENU_BREADCRUMB">
					<Breadcrumb>
						<Breadcrumb.Section><Link to="/" >{this.state.programTitle}</Link></Breadcrumb.Section>
						<Breadcrumb.Divider style={{color: 'white'}}>&gt;</Breadcrumb.Divider>
						<Breadcrumb.Section>
							<Link
								to={'/journey/'+this.state.journeyId}
								onClick={()=>{this.reload()}}
							>{this.state.journeyTitle}</Link>
						</Breadcrumb.Section>
						{
							this.state.activeTrailData !== null ?
							<>
								<Breadcrumb.Divider style={{color: 'white'}}>&gt;</Breadcrumb.Divider>
								<Breadcrumb.Section active>{this.state.activeTrailData.title}</Breadcrumb.Section>
							</> : <></>
						}
					</Breadcrumb>
				</div>
			</Menu.Item>
			<Menu.Menu position="right" >
				<Menu.Item fitted style={{marginRight:'4px'}} className="HIDE_ON_MOBILE">
				
					{
						showProject
						?	<Button.Group size="tiny">
								<Popup
									trigger={
										<Button icon color="black" className="MENU_TOP_BUTTON MENU_BUTTON_SPACE" 
											onClick={()=>{this.setModalProjectVisible(true)}} 
										>
											<Icon name='code' />
										</Button>
									}
									content='Visualizar o Projeto'
									on='hover'
								/>
							</Button.Group>
						:	<></>									
					}

					{
						showMessenger
						?	<Button.Group size="tiny" >
								<Popup
									trigger={
										<Button icon color="black" className="MENU_TOP_BUTTON MENU_BUTTON_SPACE" onClick={()=>{this.showMessenger()}}>
											<Icon name='question' />
										</Button>										
									}
									content='Tire suas dúvidas'
									on='hover'
								/>
							</Button.Group>
						:	<></>
					}

					<Button.Group size="tiny">
						<Popup
							trigger={
								<Button icon color="black" className="MENU_TOP_BUTTON" onClick={()=>{this.changeViewMode('V')}} >
									<Icon name='video' />
								</Button>
							}
							content='Modo Vídeo'
							on='hover'
						/>
						<Popup
							trigger={
								<Button icon color="black" className="MENU_TOP_BUTTON" onClick={()=>{this.changeViewMode('T')}} >
									<Icon name='tasks' />
								</Button>
							}
							content='Modo Texto'
							on='hover'
						/>
					</Button.Group>

				</Menu.Item>
				<Dropdown
					item icon={null}
					trigger={
						<>
							<span className="HIDE_ON_MOBILE">
								{this.state.userData.firstName}
							</span>
							<Image avatar src={profileIcon} className="DD_AVATAR" /> 
						</>
					}
					className="DD_MENU_BUTTON"
				>
					<Dropdown.Menu className="DD_MENU">
						<Dropdown.Header icon="user" content={this.state.userData.firstName + ' '  + this.state.userData.lastName} className="DD_MENU_LABEL" />
						<Dropdown.Header icon="mail" content={this.state.userData.email} className="DD_MENU_LABEL" />
						<Dropdown.Header icon="trophy" content={this.state.programTitle}  className="DD_MENU_LABEL" />
						<Dropdown.Divider />
						{
							showProject ?
								<Dropdown.Item icon="code" text='Visualizar o Projeto' className="DD_MENU_ITEM" onClick={()=>{this.setModalProjectVisible(true)}} />
							:	<></>
						}
						{
							showMessenger
							?	<Dropdown.Item icon="question" text='Tire suas Dúvidas' className="DD_MENU_ITEM" onClick={()=>{this.showMessenger()}} />
							:	<></>
						}
						<Dropdown.Item icon="video" text='Modo Video' className="DD_MENU_ITEM" onClick={ ()=>{this.changeViewMode('V')} } />
						<Dropdown.Item icon="tasks" text='Modo Texto' className="DD_MENU_ITEM" onClick={ ()=>{this.changeViewMode('T')} } />
						<Dropdown.Divider />
						<Dropdown.Item icon="user" text='Meu Perfil' className="DD_MENU_ITEM" onClick={ ()=>{this.setModalViewProfileVisible()} } />
						<Dropdown.Item icon="lock" text='Trocar Senha' className="DD_MENU_ITEM" onClick={ ()=>{this.setModalChangePasswordVisible(true)} } />
						<Dropdown.Item icon="power off" text='Sair' className="DD_MENU_ITEM" onClick={this.logout} />
					</Dropdown.Menu>
				</Dropdown>
			</Menu.Menu>
		</Menu>;
	}

	renderChallengeQuestions(){
		return this.state.questions.map(function(question, questionIndex){
			return <Segment key={questionIndex}>
				<div className="CHALLENGE_QUESTION">
					<ReactMarkdown 
						source={`${(questionIndex+1)}. ${question.content}`}
						className='markdown-body QUESTION-MARKDOWN'
						renderers={
							{
								link: props => <a href={props.href} target="_blank" rel="noopener noreferrer">{props.children}</a>,
								image: MDImage,
								code: CodeBlock
							}
						}
					/>
					{	question.type === 'C'
						?	<div className="QUESTION_NOTE">
								(selecione as {question.correctAnswers} opções corretas) &nbsp;
							</div>
						:	<></>
					}
					{
						question.answers.map(function(answer ,answerIndex){
							return <div
									key={answerIndex}
									className={'ANSWER_ROW'+(answer.isActive ? ' ACTIVE '+this.getQuestionAnswerStatus(questionIndex) :'') } 
									onClick={()=>{this.answerClickHandler(questionIndex, answerIndex)}}
								>
									{   question.type === 'M'
										?   <Label size="large" className="ANSWER_HANDLER" circular>
												{this.indexToChar(answerIndex)}
											</Label>
										:   <Label size="large" className="ANSWER_CHECKBOX">
												{this.indexToChar(answerIndex)}
											</Label>
									}
									{answer.answer}
								</div>;
						}, this)
					}
				</div>
			</Segment>;
		}, this)
	}

	renderChallengeActivity(){
		var challengeData = this.state.challengeData;
		var content = '';
		if (challengeData.intro !== null && challengeData.intro.length > 10){
			content += challengeData.intro + '\n\n';
		}
		for(var i=0; i < challengeData.activities.length; i++){
			content += challengeData.activities[i].content + '\n\n';
			if (i < this.state.activityIsCorrect.length){
				var isCorrect = this.state.activityIsCorrect[i];
				var message = this.state.activityMessages[i];
				if (isCorrect === false && message !== null && message > " "){
					content += '> #### Erro: '+ message.replace(/_/gm,"\\_") + '\n\n';
				}
			}
		}
		if (content.length > 10){
			return <Segment>
				<ReactMarkdown 
					source={content}
					className='markdown-body MARKDOWN_CONTENT'
					renderers={
						{
							link: props => <a href={props.href} target="_blank" rel="noopener noreferrer">{props.children}</a>,
							image: MDImage,
							code: CodeBlock
						}
					}
				/>
				{ this.renderWorkspaceLauncher() }
			</Segment>;
		}
		return <></>;
	}

	renderWorkspaceLauncher(){
		return 	<div className="WORKSPACE_LAUNCH">
			<Dropdown 
				className="WORKSPACE_SELECTOR"
				value={this.state.workspaceOption} 
				fluid
				selection
				onChange={(event, {value})=>{this.setWorkspaceOption(value)}}  
				options={this.getWorkSpaceOptions()}
			/>
			<Button
				className="CHALLENGE_BUTTON"
				content={"Acessar"}
				onClick={this.launchWorkspace}
			/>
		</div>;
	}

	renderQuizClosing(){
		return <Segment.Group className="CHALLENGE_GROUP">
			<Segment textAlign="center" className="CHALLENGE_COMPLETE">
				<Icon name="check" size="huge" color="black" className="animated tada"  />
				<Header as="h1" color="black" className="COMPLETE_TITLE">
					O desafio <strong>Pare &amp; Pense</strong> foi concluído com&nbsp;sucesso!
				</Header>
			</Segment>
		</Segment.Group>;
	}

	renderActivityClosing(){
		return <Segment.Group className="CHALLENGE_GROUP">
			<Segment textAlign="center" className="CHALLENGE_COMPLETE">
				<Icon name="check" size="huge" color="black" className="animated tada"  />
				<Header as="h1" color="black" className="COMPLETE_TITLE">
					A atividade <strong>Coloque em Prática</strong> foi concluída com sucesso!
				</Header>
			</Segment>
		</Segment.Group>;
	}

	renderObjectives(embed){
		if (this.state.activeTrailData.objectives === null
				|| this.state.activeTrailData.objectives.length < 1)
		{
			return <></>;
		}
		var objectives = this.state.activeTrailData.objectives;
		var content =
			(embed === true ? '### Objetivos de aprendizagem\n\n' : '')+
			'Após concluir esta trilha, você estará apto a:\n\n'
		;
		for(var i=0; i<objectives.length; i++){
			content += '* ' + objectives[i].skill + '\n';
		}
		content += '\n\n';
		return <div>
			{	embed === true
				?	<></>
				:	<div className="TRAIL_STEP">
						<h2>Objetivos de aprendizagem</h2>
					</div>
			}
			<ReactMarkdown
				className='markdown-body MARKDOWN_CONTENT'
				source={content}
			/>
		</div>;
	}

	renderTrailSteps(){
		return this.state.activeTrailData.steps.map(function(step, stepIndex){
			var content = step.content + '\n\n';
			if (step.activity !== null && step.activity.length > 10){
				content += step.activity + '\n\n';
			}
			this.titleRefs[stepIndex] = React.createRef();
			var showVideo = false;
			var showPlayButton = false;
			var showFaqButton = false;
			var autoplay = false;
			if (step.hasVideo){
				if (this.state.journeyViewMode !== "T"){
					showVideo = true;
				}else if (step.showInlineVideo !== null && step.showInlineVideo === true){
					showVideo = true;
					autoplay = this.activeVideoStepIndex === stepIndex;
				}else{
					showPlayButton = true;
				}
			}
			if (step.faqs && step.faqs.length > 0){
				showFaqButton = true;
			}
			return <div key={stepIndex}>
				<div className="TRAIL_STEP">
					<h2 ref={this.titleRefs[stepIndex]}>{step.title}&nbsp;&nbsp;
						{	
							showPlayButton
							?	<Popup
									trigger={
										<Icon
											name="play circle outline"
											className="INLINE_STEP_BUTTON"
											onClick={()=>{this.playInlineVideo(stepIndex)}}
										/>
									}
									content='Assistir ao Vídeo'
									on='hover'
								/>
							:	<></>
						}
						{	
							showFaqButton
							?	<Popup
									trigger={
										<Icon
											name="question circle outline"
											className="INLINE_STEP_BUTTON"
											onClick={()=>{this.setModalFaqVisible(true, stepIndex)}}
										/>
									}
									content='Dúvidas frequentes'
									on='hover'
								/>							
							:	<></>
						}
					</h2>
					{	
						showVideo
						?	<Vimeo
								video={step.videoExternalPath}
								autoplay={autoplay}
								className="INLINE_VIDEO"
								onEnd={()=>{this.onEndInlineVideo(stepIndex)}}
							/>
						:	<></>
					}
				</div>
				<ReactMarkdown
					className='markdown-body MARKDOWN_CONTENT'
					source={content} 
					renderers={
						{
							link: props => <a href={props.href} target="_blank" rel="noopener noreferrer">{props.children}</a>,
							image: MDImage,
							code: CodeBlock
						}
					}
				/>
				{ this.renderStepResources(step) }
			</div>;
		}, this)
	}

	renderStepResources(step){
		if (step.resources && step.resources.length > 0){
			return <Segment.Group className="STEP_RESOURCE_GROUP">
				{
					step.resources.map(function(resource, resourceIndex){
						return <Segment key={resourceIndex} className="STEP_RESOURCE_ITEM">
							<Grid verticalAlign='middle'>
								<Grid.Row>
									<Grid.Column width="15">
										<p><a href={ resource.content } target="_blank" rel="noopener noreferrer" className="STEP_RESOURCE_TITLE">{ resource.title }</a></p>
										<p>~ { this.formatMinutes(resource.estimatedTime) }</p>
									</Grid.Column>
									<Grid.Column width="1">
										<a href={ resource.content } target="_blank" rel="noopener noreferrer">
											<Button icon circular>
												<Icon name='arrow right' />
											</Button>
										</a>
									</Grid.Column>
								</Grid.Row>
							</Grid>
						</Segment>;
					}, this)
				}
			</Segment.Group>;
		}
		return <></>;
	}

	renderTrailResources(){
		var resources = this.state.activeTrailData.resources;
		if (resources.length > 0){
			var content = '';
			for(var i = 0 ; i < resources.length ; i ++){
				content += '* [' + resources[i].title + ']('+ resources[i].content +')\n';
			}
			content += '\n\n';
			var index = this.state.activeTrailData.steps.length;
			this.titleRefs[index] = React.createRef();
			return <div key={index} className='markdown-body MARKDOWN_CONTENT'>
				<h2 ref={this.titleRefs[index]}>Recursos</h2>
				<ReactMarkdown
					source={content} 
					renderers={
						{
							link: props => <a href={props.href} target="_blank" rel="noopener noreferrer">{props.children}</a>,
							image: MDImage,
							code: CodeBlock
						}
					}
				/>
			</div>;
		}
		return <></>;
	}

	renderTrailClosing(){
		return <Segment.Group className="CHALLENGE_GROUP">
			<Segment textAlign="center" className="CHALLENGE_COMPLETE">
				<Icon name="trophy" size="huge" color="black" className="animated tada"  />
				<Header as="h1" color="black" className="COMPLETE_TITLE">
					A trilha <strong>{this.state.activeTrailData.title}</strong> foi concluída com&nbsp;sucesso!
				</Header>
				{
					this.state.activeTrailData.score !== null && this.state.activeTrailData.score > 0
					?	<Label className={"CHALLENGE_SCORE ORANGE_BUTTON"}>
							Ganhou + {this.state.activeTrailData.score} pontos
						</Label>
					:	<></>
				}
				<Button basic onClick={this.retakeChallenge} className={"CHALLENGE_RETAKE"}>Refazer o Desafio</Button>
			</Segment>
			<Segment textAlign="right">
				<Button className="CHALLENGE_BUTTON"
					icon="chevron right"
					primary
					content={"Avançar"}
					onClick={this.onNextButton}
					labelPosition="right"
				/>
			</Segment>
		</Segment.Group>;
	}

	renderStepChallenge(){
		var step = this.state.activeTrailData.steps[this.state.activeStepIndex],
			isChallengeComplete = true,
			areAllQuestionsAnswered = true,
			hasFailedQuestions = false;
		for(var i=0; i < step.questions.length; i++){
			if (step.questions[i].isComplete !== true){
				isChallengeComplete = false;
			}
			if (this.state.answerIsCorrect === null
				|| i >= this.state.answerIsCorrect.length
					|| this.state.answerIsCorrect[i] === null)
			{
				areAllQuestionsAnswered = false;
			}else if (this.state.answerIsCorrect[i] === false){
				areAllQuestionsAnswered = false;
				hasFailedQuestions = true;
			}
		}
		return <Segment.Group className="CHALLENGE_GROUP">
			<Segment>
				<h2 className="CHALLENGE_TITLE">Pare &amp; Pense! &nbsp; &nbsp;
					{	this.state.hasFailedQuestions || areAllQuestionsAnswered || isChallengeComplete
						?	<></>
						:	<Label className={"CHALLENGE_POINTS ORANGE_BUTTON"}>
								+ {step.challengePoints} pontos
							</Label>
					}
				</h2>
			</Segment>
			{ this.renderChallengeQuestions(step.questions) }
				{	areAllQuestionsAnswered
					?	<Segment textAlign="right">
							<Button className="CHALLENGE_BUTTON GREEN_BUTTON"
								primary
								content={"Correto! Vamos Avançar"}
								disabled={!this.state.isChallengeComplete}
								onClick={this.onNextButton}
								icon="chevron right"
								labelPosition="right"
							/>
						</Segment>
					:	<Segment textAlign="left">
							<Button
								className={"CHALLENGE_BUTTON"+(hasFailedQuestions?" YELLOW_BUTTON":"")}
								primary
								content={
									hasFailedQuestions
									?	"Ops! Tente Novamente"
									:	"Enviar Resposta" + (step.questions.length > 1 ? "s" : "")
								}
								disabled={!this.state.isChallengeComplete}
								onClick={this.submitStepQuiz} 
							/>
						</Segment>
				}			
			{
				this.state.isAnswerSubmitted ? 
					<Dimmer inverted active className="animated fadeIn"><Loader /></Dimmer>
				: <></>
			}
		</Segment.Group>;
	}

	renderTrailChallenge(){
		if (this.state.isLoading || this.state.activeTrailData === null || this.state.activeTrailData.type === 'Q'){
			return <></>;
		}
		var trail = this.state.activeTrailData;
		if (!this.state.isChallengeLoaded){
			this.loadChallengeData(trail.id);
			return <></>;
		}
		if (this.state.challengeData === null){
			return <></>;
		}else if (this.state.challengeData.isComplete && !this.state.isChallengeRetake){
			return this.renderTrailClosing();
		}
		var challenge = this.state.challengeData,
			isChallengeComplete = true,
			areAllQuestionsAnswered = true,
			areAllActivitiesComplete = true,
			hasFailedQuestions = false,
			hasFailedActivity = false;
		for(var i=0; i < challenge.questions.length; i++){
			if (challenge.questions[i].isComplete !== true){
				isChallengeComplete = false;
			}
			if (this.state.answerIsCorrect === null
				|| i >= this.state.answerIsCorrect.length
					|| this.state.answerIsCorrect[i] === null)
			{
				areAllQuestionsAnswered = false;
			}else if (this.state.answerIsCorrect[i] === false){
				areAllQuestionsAnswered = false;
				hasFailedQuestions = true;
			}
		}
		for(i=0; i < challenge.activities.length; i++){
			if (challenge.activities[i].isComplete !== true){
				isChallengeComplete = false;
			}
			if (this.state.activityIsCorrect === null
				|| i >= this.state.activityIsCorrect.length
					|| this.state.activityIsCorrect[i] === null)
			{
				areAllActivitiesComplete = false;
			}else if (this.state.activityIsCorrect[i] === false){
				areAllActivitiesComplete = false;
				hasFailedActivity = true;
			}
		}
		return <>
		{
			challenge.questions.length > 0
			?	this.state.challengeData.hasQuestionPending || this.state.isChallengeRetake
				?	<Segment.Group className="CHALLENGE_GROUP">
						<Segment>
							<h2 className="CHALLENGE_TITLE">Pare &amp; Pense! &nbsp; &nbsp;
								{	this.state.hasFailedQuestions
										|| this.state.isChallengeRetake
											|| areAllQuestionsAnswered
												|| isChallengeComplete
									?	<></>
									:	<Label className={"CHALLENGE_POINTS ORANGE_BUTTON"}>
											+ {challenge.questionPoints} pontos
										</Label>
								}
							</h2>
						</Segment>
						{ this.renderChallengeQuestions(challenge.questions) }
						<Segment textAlign="left">
							<Button
								className={"CHALLENGE_BUTTON"+(hasFailedQuestions?" YELLOW_BUTTON":"")}
								primary
								content={
									hasFailedQuestions
									?	"Ops! Tente Novamente"
									:	"Enviar Resposta" + (challenge.questions.length > 1 ? "s" : "")
								}
								disabled={!this.state.isChallengeComplete}
								onClick={this.submitTrailQuiz} 
							/>
						</Segment>
					</Segment.Group>

				:	this.renderQuizClosing()

			:	<></>
		}
		{
			challenge.activities.length > 0
			?	this.state.challengeData.hasActivityPending || this.state.isChallengeRetake
				?	<>
						<Segment.Group className="CHALLENGE_GROUP">
							<Segment>
								<h2 className="CHALLENGE_TITLE">
									{
										challenge.title !== null && challenge.title > ' '
											?	challenge.title
											:	'Coloque em Prática!'
									}
									&nbsp; &nbsp;
									{	this.state.hasFailedActivities
											|| this.state.isChallengeRetake
												|| areAllActivitiesComplete
													|| isChallengeComplete
										?	<></>
										:	<Label className={"CHALLENGE_POINTS ORANGE_BUTTON"}>
												+ {challenge.activityPoints} pontos
											</Label>
									}
								</h2>
							</Segment>
							{ this.renderChallengeActivity(challenge.activities) }			
							<Segment textAlign="left">
								<Button
									className={"CHALLENGE_BUTTON"+(hasFailedActivity?" YELLOW_BUTTON":"")}
									primary
									content={
										hasFailedActivity
										?	"Ops! Tente Novamente"
										:	"Verificar Atividade"
									}
									onClick={()=>{ this.setModalWorkspaceConnect(true) }} 
								/>
								{this.state.isMentoring &&
									<Button
										color="orange"
										size="big"
										content="Inspecionar Atividade"
										onClick={()=> {
											this.loadInspector(this.state.activeTrailData.id)
											this.setState((state) => {
												state.inspectorLoading = true
												return state
											})
										}}
										loading={this.state.inspectorLoading}
									/>
								}
							</Segment>
						</Segment.Group>

						<Modal
							closeIcon
							open={this.state.openModalReport}
							onClose={this.openModalReport}
							onOpen={this.openModalReport}
							size="large"
							className="DEBUG"
						>
              {this.state.workspace !== undefined && <div className="DEBUG-CHALLENGE">
                <details className="DEBUG-DETAILS-TITLE">
									<summary className="DEBUG-CHALLENGE-TITLE">
										<span>Inspector</span>
									</summary>

									<div className="DEBUG-CONTAINER">
                  {this.state.workspace.cacheData !== undefined &&
                      <>
                        <Dropdown 
                          search
                          selection
                          className="DEBUG-SELECT"
                          placeholder='Selecione o tipo' 
                          options={
                            this.state.workspace.cacheData
                              .filter((fill) => ['ApexClassList','ObjectList'].indexOf(fill.type) >= 0)
                              .map((cache) => {
                                return {  key: cache.type, value: cache.type, text: cache.type }
                              })
                          } 
                          onChange={(event, {value}) => {
                            this.setState((state) => {
                              state.inspectorMetaDataType = value
                              return state
                            })
                          }}
                        />
                        <Dropdown 
                          search
                          selection
                          className="DEBUG-SELECT"
                          placeholder='Selecione o nome' 
                          options={
                            this.state.inspectorMetaDataType === 'ObjectList' 
                              ? this.state.inspectorObjectList 
                              :this.state.inspectorApexClassList
                          }
                          onChange={(event, {value}) => {
                            this.setState((state) => {
                              state.inspectorMetaDataName = value
                              return state
                            })
                          }}
                        />
                        <Button 
                          loading={this.state.inspectorLoading}
                          color='orange' 
                          onClick={() => this.loadList()}
                        >
                          Carregar
                        </Button>
                      </>
                    }
                    <div className="DEBUG-CODE DEBUG-PADDING">
                      <pre className="USER-SELECT">
                        {this.state.inspectorContent}
                      </pre>
                    </div>
									</div>
								</details>
							</div>}

							{this.state.workspace !== undefined && <div className="DEBUG-CHALLENGE">
								<details className="DEBUG-DETAILS-TITLE">
									<summary className="DEBUG-CHALLENGE-TITLE">
										<span>Workspace</span>
									</summary>

									<div className="DEBUG-CONTAINER">
										<Highlight language="javascript">
											<div className="DEBUG-CODE">
												<pre className="USER-SELECT">
													{JSON.stringify(this.state.workspace, null, 2)}
												</pre>
											</div>
										</Highlight>                    
									</div>
								</details>
							</div>}

							{this.state.inspectorData !== undefined && this.state.inspectorData.map((challenge) => (
								<div key={challenge.id} className="DEBUG-CHALLENGE">
                  <details className="DEBUG-DETAILS-TITLE">
											<summary className="DEBUG-CHALLENGE-TITLE">
                        <span>
                          {challenge.isComplete && <Icon name='check' color='green' />}
                          {challenge.hasErrors && <Icon name='exclamation' color='red' />}
                          {`Challenge #${challenge.id}`}
                        </span>
                      </summary>

                    {challenge.evaluations.map((evaluation, index)=> (
                      <details key={evaluation.sequence} className="DEBUG-DETAILS">
                        <summary className="DEBUG-SUMMARY">
                          <span>
                            {evaluation.isCorrect && <Icon name='check' color='green' />}
                            {(evaluation.errorMessage !== "" || evaluation.errorCode > 0) && <Icon name='exclamation' color='red' />}
                              {evaluation.type === "A" 
                              ? "ApexClass #" 
                              : evaluation.type === "U" 
                                ? "UnitTest #" 
                                : evaluation.type === "M"
                                  ? "Metadata #"
                                  : evaluation.type === "Q"
                                    ? "Query #"
                                    : "Other #"
                              }
                              {index + 1}
                          </span>
                        </summary>

                        <div className="DEBUG-CONTAINER">
                          <span>Activity Metadata</span>
                          <Highlight language="javascript">
                            <div className="DEBUG-CODE">
                              <pre className="USER-SELECT">
                                {JSON.stringify(evaluation.content, null, 2)}
                              </pre>
                            </div>
                          </Highlight>
                        </div>
                        
                        <div className="DEBUG-CONTAINER">
                          <span>Execution Metadata</span>
                          <Highlight language="javascript" className="NO-PADDING">
                            <div className="DEBUG-CODE">
                              <pre className="USER-SELECT">
                                {JSON.stringify(evaluation.activityMetadata, null, 2)}
                              </pre>
                            </div>
                          </Highlight>
                        </div>

                        {evaluation.errorMessage !== "" && <div className="DEBUG-CONTAINER">
                          <span>Error Message</span>
                          <pre className="USER-SELECT">
                            {evaluation.errorMessage}
                          </pre>
                        </div>}
                      </details>
                    ))}
                  </details>
								</div>
							))}
						</Modal>
					</>
				:	this.renderActivityClosing()
			: <></>
		}
		</>;
	}

	renderTrailQuiz(){
		if (this.state.isLoading || this.state.activeTrailData === null || this.state.activeTrailData.type !== 'Q'){
			return <></>;
		}
		var trail = this.state.activeTrailData;
		if (!this.state.isQuizLoaded){
			this.loadQuizData(trail.id);
			return <></>;
		}
		if (this.state.quizData === null){
			return <></>;
		}
		var data = this.state.quizData;
		var quizTypeLabel = data.quizType === 'P' ? 'Exercício' : 'Teste';
		var isTimebox = (data.timeLimit !== null && data.timeLimit > 0 && data.remainingTime !== null && data.remainingTime > 1);
		if (data.quizId !== null && data.quizId > 0){
			if (data.isComplete){
				var quizScorePercentage = null;
				var quizPassingPercentage = null;
				var hasPassed = data.quizScore >= data.quizPassingScore;
				if (data.quizPoints > 0){
					quizScorePercentage = Math.round(data.quizScore * 1000 / data.quizPoints) / 10;
					quizPassingPercentage = Math.round(data.quizPassingScore * 1000 / data.quizPoints) / 10;
				}
				return <Segment.Group className="CHALLENGE_GROUP">
					<Segment textAlign="center" className="CHALLENGE_COMPLETE">
						{
							hasPassed
							?	<Icon name="trophy" size="huge" color="black" className="animated tada"  />
							:	<></>
						}
						<Header as="h1" color="black" className="COMPLETE_TITLE">
						{
							hasPassed
							?	<>
									O {quizTypeLabel} <strong>{this.state.activeTrailData.title}</strong> foi concluído com sucesso
									{quizScorePercentage !== null && quizScorePercentage > 0 ? ', você acertou '+quizScorePercentage+'% das questões.' : '!'}
								</>
							:	this.state.activeTrailData.isComplete
								?	<>
										O último {quizTypeLabel} <strong>não</strong> foi concluído com sucesso
										{quizScorePercentage !== null &&	', você acertou '+quizScorePercentage+'% das questões'}.
									</>
								:	<>
										O {quizTypeLabel} <strong>não</strong> foi concluído com sucesso
										{quizScorePercentage !== null && ', você acertou '+quizScorePercentage+'%'}
										{quizPassingPercentage !== null && ', é necessário acertar '+quizPassingPercentage+'% das questões'}.
									</>
						}
						</Header>
						{
							hasPassed && this.state.activeTrailData.score !== null && this.state.activeTrailData.score > 0
							&&	<Label className={"CHALLENGE_SCORE ORANGE_BUTTON"}>
									Ganhou + {this.state.activeTrailData.score} pontos
								</Label>
						}
						{	data.isRetakeAllowed
							&&	<Button basic onClick={()=>{this.setModalTrailQuizVisible(true)}} className={"CHALLENGE_RETAKE"} >
									Refazer o { quizTypeLabel }
								</Button>
						}
					</Segment>
					{
						hasPassed
						?	<Segment textAlign="right">
								<Button className="CHALLENGE_BUTTON"
									icon="chevron right"
									primary
									content={"Avançar"}
									onClick={this.onNextButton}
									labelPosition="right"
								/>
							</Segment>
						:	<></>
					}
				</Segment.Group>;
			}else{
				return <Segment.Group className="CHALLENGE_GROUP">
					<Segment textAlign="center" className="CHALLENGE_COMPLETE">
						<Header as="h1" color="black" className="COMPLETE_TITLE">
							{ isTimebox ? 'Ainda restam '+data.remainingTime+' minutos para você ' : 'Clique no botão abaixo para ' }
							terminar de responder as perguntas do { quizTypeLabel }.
						</Header>
						<Button
							basic
							onClick={()=> this.setModalTrailQuizVisible(true)}
							className={"CHALLENGE_BUTTON"} >
								Retomar o { quizTypeLabel }
						</Button>
					</Segment>
				</Segment.Group>;
			}
		}
		var title;
		var content;
		if (data.challengeTitle !== null && data.challengeTitle.length > 10){
			title = data.challengeTitle;
		}else{
			title = 'Vamos Praticar!';
		}
		if (data.challengeIntro !== null && data.challengeIntro.length > 10){
			content = data.challengeIntro;
		}else{
			if (isTimebox){
				content = 'Você terá '+data.remainingTime+' minutos para responder as '+
				(data.challengeQuestions !== null && data.challengeQuestions > 0 ? data.challengeQuestions+' ' : '')+
				'perguntas de multipla escolha que compõem o '+quizTypeLabel+'.';	
			}else{
				content = 'O '+quizTypeLabel+' é composto por '+
				(data.challengeQuestions !== null && data.challengeQuestions > 0 ? data.challengeQuestions+' ' : '')+
				'perguntas de multipla escolha.';	
			}
			content += "\n\nClique no botão abaixo para iniciar.";
		}
		return <Segment.Group className="CHALLENGE_GROUP">
			<Segment>
				<h2 className="CHALLENGE_TITLE">{ title } &nbsp; &nbsp;
					<Label className={"CHALLENGE_POINTS ORANGE_BUTTON"}>
						+ {data.challengePoints} pontos
					</Label>
				</h2>
			</Segment>
			<Segment>
				<ReactMarkdown 
					source={content}
					className='markdown-body MARKDOWN_CONTENT'
					renderers={
						{
							link: props => <a href={props.href} target="_blank" rel="noopener noreferrer">{props.children}</a>,
							image: MDImage,
							code: CodeBlock
						}
					}
				/>

				{!this.state.isChallengeRetake ? (
					<div>
						<Modal
							open={this.state.open}
							onClose={this.openOrClose}
							onOpen={this.openOrClose}
						>
							<Modal.Content className='ALERT-TITLE'>
								Você está prestes a iniciar o {data.challengeTitle}, uma vez iniciado não poderá refazer. 
								Deseja continuar?
							</Modal.Content>
							<Modal.Actions>
								<Button onClick={this.openOrClose}>Vou fazer mais tarde.</Button>
								<Button positive onClick={() => this.setModalTrailQuizVisible(true)}>Tudo pronto, pode começar.</Button>
							</Modal.Actions>
						</Modal>
						<Button
							basic
							onClick={this.openOrClose}
							className={"CHALLENGE_BUTTON"} >
								Iniciar o { quizTypeLabel }
						</Button>
					</div>
				) : (
					<Button
						basic
						onClick={()=> this.setModalTrailQuizVisible(true)}
						className={"CHALLENGE_BUTTON"} >
							Iniciar o { quizTypeLabel }
					</Button>
				)}
			</Segment>			
		</Segment.Group>;
	}

	messengerInputChangeHandler(event){
		const data = event.target.value;
		this.setState(function(state){
			state.messengerInput = data;
			return state;
		});
	}

	messengerInputFocusHandler(active){
		if (active === false && this.state.messengerInput !== null && this.state.messengerInput.length > 10){
			return;
		}
		this.setState(function(state){
			state.messageInputActive = active;
			return state;
		});
		if (active){
			setTimeout(
				this.messengerScrollCanvas,
				250
			);	
		}
	}

	messengerScrollCanvas(){
		if (this.elemContentScroll != null && this.elemContentScroll.current != null){
			this.elemContentScroll.current.scrollTo({
				top: 999999,
				left: 0,
				behavior: 'smooth'
			});
		}
	}

	getMessageSenderName(mentorId){
		var mentors = this.state.messengerData.mentors;
		if (mentors != null && mentors.length > 0){
			for (var i=0; i<mentors.length; i++){
				if (mentors[i].id === mentorId){
					return mentors[i].name;
				}
			}
		}
		return '';
	}

	renderTrailMessages(){
		if (this.state.messengerData === null
				|| this.state.messengerData.trailMessages === null
					|| this.state.messengerData.trailMessages.length <= 0)
		{
			return <></>;
		}
		var lastMessageIndex = this.state.messengerData.trailMessages.length - 1;
		var showDeleteButton = (this.state.messengerData.trailMessages[lastMessageIndex].type === 'Q');
		return <>
			{
				this.state.messengerData.trailMessages.map(function(message, messageIndex){
					return <Comment key={messageIndex}>
						<Comment.Content
							className={
								"MESSENGER_BALLOON" +
								(message.type === "A" ? " MENTOR" : " STUDENT") +
								(message.status === 'D' ? " DELETED" : "")
							}
						>
							<Comment.Author as='span'>
								{	message.type === "A"
									? this.getMessageSenderName(message.mentorId)
									: this.state.userData.firstName + ' '  + this.state.userData.lastName
								}
							</Comment.Author>
							<Comment.Metadata>
								<div>{ this.displayDatetime( message.sentDate ) }</div>
								{
									showDeleteButton && messageIndex === lastMessageIndex && message.status !== 'D'
									?	<>
											<Popup
												trigger={
													<Icon
														link
														color='red'
														name='trash'
														onClick={()=>{this.showDeleteMessageConfirmation(true)}}
													/>
												}
												content='Excluir a mensagem'
												on='hover'
											/>
											<Confirm
												open={this.state.isMessageDeletePending}
												content='Deseja mesmo excluir a sua mensagem?'
												cancelButton="Cancelar"
												confirmButton="Pode Excluir"
												onCancel={()=>{this.showDeleteMessageConfirmation(false)}}
												onConfirm={this.deleteLastMessage}
											/>
										</>
									:	<></>
								}
							</Comment.Metadata>

							<Comment.Text>
								{
									message.status === 'D'
									?	<span><Icon name='ban' /> Mensagem excluída</span>
									:	<ReactMarkdown
											source={message.message}
											className='markdown-body MESSENGER_TEXT'
											renderers={
												{
													link: props => <a href={props.href} target="_blank" rel="noopener noreferrer">{props.children}</a>,
													image: MDImage,
													code: CodeBlock
												}
											}
										/>
								}
							</Comment.Text>
						</Comment.Content>
					</Comment>;
				},this)
			}
		</>;
	}

	renderMessenger(){
		if (this.state.isLoading || this.state.messengerTrailIndex < 0 || this.state.trails.length <= this.state.messengerTrailIndex){
			return <></>;
		}
		var trail = this.state.trails[this.state.messengerTrailIndex];
		return <>
			<Grid verticalAlign='middle' className="MESSENGER_HEADER">
				<Grid.Row className="MESSENGER_HEADER_ROW">
					<Grid.Column width="15">
						<span className="MESSENGER_HEADER_TITLE">Dúvidas sobre: {trail.title}</span>
					</Grid.Column>
					<Grid.Column width="1">
						<Button icon circular onClick={this.closeMessenger} >
							<Icon name='close' />
						</Button>
					</Grid.Column>
				</Grid.Row>
			</Grid>
			<div className="MESSENGER_CONTAINER">
				<div className="MESSENGER_CANVAS" ref={this.elemContentScroll}>
					<Comment.Group className="MESSENGER_LINE">
						{ this.renderFaq() }
						{ this.renderTrailMessages() }
						<Comment>
							<Comment.Content className="MESSENGER_INPUT_BALLOON">
								<Comment.Author as='span'>Está com dúvida?</Comment.Author>
								<Comment.Text>
									<TextArea 
										className={'MESSENGER_INPUT' + (this.state.messageInputActive?' ACTIVE':'')}
										placeholder="escreva aqui a sua pergunta..." 
										onFocus={()=>{this.messengerInputFocusHandler(true)}}
										onBlur={()=>{this.messengerInputFocusHandler(false)}}
										value={this.state.messengerInput} 
										onChange={(event)=>{this.messengerInputChangeHandler(event)}}
										disabled={this.state.isSendingMessage}	   
										/>
									<Button
										icon="paper plane"
										size="small"
										content="Enviar"
										color="black"
										floated="right"
										disabled={this.state.messengerInput === null || this.state.messengerInput.length < 10}
										onClick={this.sendMessage}
									/>
								</Comment.Text>
							</Comment.Content>
						</Comment>
					</Comment.Group>
				</div>
			</div>			
		</>;
	}

	renderVideoMode(){		
		if (this.state.isLoading || this.state.activeTrailData === null || this.state.activeTrailData.steps.length < 1){
			return <></>;
		}
		var trail = this.state.activeTrailData,
			step = null,
			trailContent = null,
			showStepResources = false,
			showStopAndThink = false,
			showActivities = false,
			videoAutoplayOn = this.videoAutoplayOn;
		this.videoAutoplayOn = false;
		if (this.state.activeStepIndex < trail.steps.length){
			step = trail.steps[this.state.activeStepIndex];
			if (step.hasVideo === false){
				trailContent = '## ' + step.title + '\n\n' + step.content + '\n\n';
				if (step.activity !== null && step.activity.length > 10){
					trailContent += step.activity + '\n\n';
					showActivities = true;					
				}
				if (step.resources != null && step.resources.length > 0){
					showStepResources = true;
				}
				if (step.questions != null && step.questions.length > 0){
					showStopAndThink = true;
				}
			}else if (this.state.displayVideoClosing
						&& ((step.activity != null && step.activity.length > 10)
							|| (step.resources != null && step.resources.length > 0)
								|| (step.questions != null && step.questions.length > 0))){
				if (step.activity != null && step.activity.length > 10){
					trailContent = '## ' + step.title + '\n\n' + step.activity;
					showActivities = true;
				}
				if (step.resources != null && step.resources.length > 0){
					showStepResources = true;
				}
				if (step.questions != null && step.questions.length > 0){
					showStopAndThink = true;
				}
			}else{
				var showFaqButton = (step.faqs && step.faqs.length > 0);
				return <>
					<div className="THEATER_CONTAINER">
						{this.state.displayVideoClosing ? 
							<div className="THEATER_OVERLAY" >
								<div className="THEATER_OVERLAY_CONTENT">
									<Button
										size="large"
										labelPosition='right'
										icon="chevron right"
										content="Avançar"
										floated="right"
										className="THEATER_PLAY_BUTTON"
										onClick={this.videoNextStepTimeoutHandler}
									/>
								</div>
							</div>
							:<></>
						}
						<Vimeo video={step.videoExternalPath} autoplay={videoAutoplayOn===true} className="EMBED_CONTAINER" onEnd={this.onEndVideo} />
					</div>
					<div className="VIDEO_CONTENT">
						<h2  className="VIDEO_TITLE">{step.title}&nbsp;&nbsp;
							{showFaqButton
								?	<Popup
										trigger={
											<Icon
												name="question circle outline"
												className="INLINE_STEP_BUTTON"
												onClick={()=>{this.setModalFaqVisible(true, this.state.activeStepIndex)}}
											/>
										}
										content='Dúvidas frequentes'
										on='hover'
									/>
								:	<></>
							}
						</h2>
						{
							this.state.activeStepIndex === 0
							? this.renderObjectives(true) : <></>
						}
					</div>
				</>;
			}
		}else if (this.state.activeStepIndex === trail.steps.length){
			trailContent = '## Recursos\n\n';
			if (trail.resources.length > 0){
				for(var i = 0 ; i < trail.resources.length ; i ++){
					trailContent += '* [' + trail.resources[i].title + ']('+ trail.resources[i].content +')\n';
				}
				trailContent += '\n\n';
			}
		}else if (this.state.activeStepIndex > trail.steps.length){
			if (this.state.activeTrailData.isComplete){
				if (this.state.isChallengeRetake){
					showStopAndThink = true;
				}else{
					return <div className="MARKDOWN_CONTAINER">
						{this.renderTrailClosing()}
					</div>;
				}
			}else{
				return <div className="MARKDOWN_CONTAINER">
					{this.renderTrailChallenge()}
				</div>;
			}
		}

		return <div className="MARKDOWN_CONTAINER">
			{
				this.state.activeStepIndex === 0 ? this.renderObjectives(false) : <></>
			}
			{
				trailContent !== null
				?	<>
						<ReactMarkdown 
							source={trailContent} 
							className='markdown-body MARKDOWN_CONTENT'
							renderers={
								{
									link: props => <a href={props.href} target="_blank" rel="noopener noreferrer">{props.children}</a>,
									image: MDImage,
									code: CodeBlock
								}
							}
						/>
					{
						showActivities && !showStepResources
						?	this.renderWorkspaceLauncher()
						:	<></>
					}
					</>

				:	<></>
			}
			{
				showStepResources
				?	this.renderStepResources(step) 
				:	<></>
			}
			{	showStopAndThink
				?	(this.state.isChallengeRetake ? this.renderTrailChallenge() : this.renderStepChallenge())
				: 	<Button
						size="large"
						labelPosition='right'
						icon="chevron right"
						content={"Avançar"}
						className={"CONTINUE_BUTTON"}
						floated="right"
						onClick={this.onNextButton}
					/>
			}
		</div>;
	}

	renderTrailMode(){
		if (this.state.isLoading
				|| this.state.activeTrailData === null
					|| this.state.trails[this.state.activeTrailIndex].id !== this.state.activeTrailData.id)
		{
			return <></>;
		}
		return <div className="MARKDOWN_CONTAINER">
			<div className="markdown-body MARKDOWN_CONTENT">
				<h1>{this.state.activeTrailData.title}</h1>
			</div>
			{ this.renderObjectives(false) }
			{ this.renderTrailSteps() }
			{ this.renderTrailResources() }
			{ this.renderTrailChallenge() }
			{ this.renderTrailQuiz() }
		</div>;
	}

	renderClosing(){
		return  <div className="MARKDOWN_CONTAINER">
			<Segment.Group className="CHALLENGE_GROUP">
				<Segment textAlign="center" className="CHALLENGE_COMPLETE">
					<Icon name="trophy" size="huge" color="black" className="animated tada"  />
					<Header as="h1" color="black" className="COMPLETE_TITLE">
						A jornada <strong>{this.state.journeyTitle}</strong> foi concluída com&nbsp;sucesso!
					</Header>
					{
						this.state.journeyScore !== null && this.state.journeyScore > 0
						?	<Label className={"CHALLENGE_SCORE GREEN_BUTTON"}>
								Ganhou {this.state.journeyScore} pontos
							</Label>
						:	<></>
					}
				</Segment>
			</Segment.Group>
		</div>;
	}

	renderTrailMenu(){
		return <Accordion exclusive={true} as={Menu} size="large" fluid vertical className="MENU_ACCORDION">
			{
				this.state.trails.map(function(trail, trailIndex){
					if (trail.isUnlocked){
						return <Menu.Item key={trailIndex}>
							<Accordion.Title
								active={trailIndex===this.state.activeTrailIndex}
								className="MENU_TRAIL"
								onClick={()=>{this.trailMenuClickHandler(trailIndex)}} 
								content={
									<>
										<span className="MENU_TITLE">{trail.title}&nbsp;&nbsp;</span>
										<span className="MENU_SUBTITLE">({this.formatMinutes(trail.estimatedTime)})</span>
									</>
								}
							/>
							{
								this.state.activeTrailData !== null && this.state.activeTrailData.steps !== null
								? <>
									<Accordion.Content
										active={trailIndex===this.state.activeTrailIndex}
										className="MENU_STEPS"
									>
										{
											this.state.activeTrailData.steps.map(function(step, stepIndex){
												return <Menu.Item 
													active={this.state.journeyViewMode === 'V' && stepIndex===this.state.activeStepIndex}
													key={stepIndex}
													content={step.title}
													icon={
														this.state.journeyViewMode === 'T' || trail.type=== 'C' || trail.type=== 'Q'
														? "" : this.getMenuItemIconStep(step)
													}
													onClick={()=>{this.stepMenuClickHandler(stepIndex)}}
													className="MENU_ITEM"
												/>
											}, this)
										}
										{
											this.state.activeTrailData !== null
												&& this.state.activeTrailData.resources !== null
													&& this.state.activeTrailData.resources.length > 0
											?
												<Menu.Item 
													active={this.state.journeyViewMode === 'V' && this.state.activeStepIndex === this.state.activeTrailData.steps.length}
													key={this.state.activeTrailData.steps.length}
													content='Recursos'
													icon={this.state.journeyViewMode === 'T'?'':'linkify'}
													onClick={()=>{this.stepMenuClickHandler(this.state.activeTrailData.steps.length)}}
													className="MENU_ITEM"
												/>
											: <></>
										}
									</Accordion.Content>
								</> : <></>
							}
						</Menu.Item>;
					}else{
						return <Menu.Item key={trailIndex}>
							<div className="MENU_TRAIL">
								<Icon name="lock" size="small" className="MENU_LOCKED" />
								<span className="MENU_TITLE">{trail.title}&nbsp;&nbsp;</span>
								<span className="MENU_SUBTITLE">({this.formatMinutes(trail.estimatedTime)})</span>
							</div>
						</Menu.Item>;
					}
				},this)
			}
		</Accordion>;
	}

  	renderFaq(){
		if (this.state.activeTrailData === null
				|| this.state.activeTrailData.faqs === null
					|| this.state.activeTrailData.faqs.length <= 0)
		{
			return <></>;
		}
        return <Comment>
			<Comment.Content className="MESSENGER_BALLOON HIGHLIGHT">
				<Comment.Author as='span'>Confira as perguntas frequentes...</Comment.Author>
				<Comment.Text>
				{   
					this.state.activeTrailData.faqs.map(function(faq, faqIndex){
						return <Accordion key={faqIndex}>
							<Accordion.Title
								className='MESSENGER_FAQ_TITLE'
								active={faqIndex === this.state.activeFaqIndex}
								index={0}
								onClick={()=>{this.faqClickHandler(faqIndex)}}
							>
								<Icon name='dropdown' />{faq.content}
							</Accordion.Title>
							<Accordion.Content
								className='MESSENGER_FAQ_CONTENT'
								active={faqIndex === this.state.activeFaqIndex}>
								<ReactMarkdown 
									source={faq.answer}
									className='markdown-body'
									renderers={
										{
											link: props => <a href={props.href} target="_blank" rel="noopener noreferrer">{props.children}</a>,
											image: Image,
											code: CodeBlock
										}
									}
								/>
							</Accordion.Content>
						</Accordion>
					},this)
				}
				</Comment.Text>
			</Comment.Content>
		</Comment>;
	}

	render(){
		document.title = 'My Pragma';
		this.titleRefs = [];
		if(!this.state.isInitialized){
			return <></>;
		}else if(this.state.userData.status === "E"){
			return  <div className="MAIN_FRAME">
				<ChangePassword
					open
					userStatus={this.state.userData.status}
					onDone={this.refreshData}
				/>
			</div>;
		}else if(this.state.userData.status === "P"){
			return  <div className="MAIN_FRAME">
				<EditProfile
					open
					userStatus={this.state.userData.status}
					onDone={this.refreshData}
				/>
			</div>;
		}else{
			var trailContent;
			if (this.state.activeTrailIndex < 0){
				trailContent = this.renderClosing();
			}else if (this.state.isLoading || this.state.activeTrailData === null){
				trailContent = <></>; 
			}else if (this.state.showMessenger){
				trailContent = this.renderMessenger();
			}else if (this.state.journeyViewMode === "T" || this.state.activeTrailData.type === "C" || this.state.activeTrailData.type === "Q"){
				trailContent = this.renderTrailMode();
			}else if (this.state.journeyViewMode === "V"){
				trailContent = this.renderVideoMode();
			}else {
				trailContent = <></>;
			}
			return 	<div className="MAIN_FRAME">

				{ this.renderTopMenu() }

				<div className="CONTENT_CONTAINER">
					{	this.state.showMessenger
						?	<div className="MESSENGER_CONTENT">
								{ trailContent }
							</div>
						:	<div className="CONTENT_VERTICAL_SCROLL" ref={this.elemContentScroll}>
								{trailContent}
							</div>
					}
				</div>

				<Menu fixed="left" vertical size="large" className={'MENU_LEFT' + (this.state.menuActive?'':' HIDE')}>
					{ this.renderTrailMenu() }
				</Menu>

				<ProjectView 
					projectId={this.state.activeTrailData !== null ? this.state.activeTrailData.projectId : null} 
					open={this.state.isProjectModalOpen} 
					onDone={this.onDoneProjectModal} 
					onCancelClick={()=>{ this.setModalProjectVisible(false) }} 
				/>

				<FaqView 
					trailData={this.state.activeTrailData}
					hasMentorAssigned={this.state.hasMentorAssigned}
					faqStepIndex={this.state.faqStepIndex}
					open={this.state.isFaqModalOpen} 
					onDone={this.onDoneFaqModal} 
					onCancelClick={()=>{ this.setModalFaqVisible(false, -1) }} 
					onShowMessenger={()=>{ 
						this.setModalFaqVisible(false, -1);
						this.showMessenger(); 
					}} 
				/>

				<TrailQuiz 
					journeyId={this.state.journeyId}
					trailId={this.state.activeTrailData !== null ? this.state.activeTrailData.id : null}
					open={this.state.isTrailQuizModalOpen} 
					onDone={this.onDoneTrailQuizModal} 
					onCancelClick={()=>{ this.setModalTrailQuizVisible(false) }} 
				/>

				<Profile
					open={this.state.isViewProfileModalOpen}
					onCancelClick={()=>{ this.setModalViewProfileVisible() }}
					onOpenModalEdit={() => this.setModalEditProfileVisible()}
					onCloseClick={() => this.setModalViewProfileVisible()}
				/>

				<EditProfile
					open={this.state.isEditProfileModalOpen}
					userStatus={this.state.userData.status}
					onDone={this.refreshData}
					onCancelClick={()=>{ this.setModalEditProfileVisible() }}
				/>

				<ChangePassword
					open={this.state.isChangePasswordModalOpen} 
					onDone={this.onDoneChangePasswordModal} 
					onCancelClick={()=>{ this.setModalChangePasswordVisible(false) }} 
				/>

				<WorkspaceConnect
					journeyId={this.state.journeyId}
					trailId={this.state.activeTrailData !== null ? this.state.activeTrailData.id : null}
					workspaceData={this.state.workspaceData}				
					open={this.state.isWorkspaceConnectModalOpen} 
					onDone={this.onDoneWorkspaceConnectModal} 
					onCancelClick={()=>{ this.setModalWorkspaceConnect(false) }}
					onChallengeEvaluationFinish={(challenges)=>{ this.onChallengeEvaluationFinish(challenges) }}
					onWorkspaceCreateSession={(data)=>{ this.onWorkspaceCreateSession(data) }}
				/>

			</div>;
		}

	}
}

function MDImage(props) {
	return <img {...props} alt="" style={{maxWidth: '100%'}} />
}

function closeFullscreen() {
	if (document.exitFullscreen) {
	  document.exitFullscreen();
	} else if (document.mozCancelFullScreen) { /* Firefox */
	  document.mozCancelFullScreen();
	} else if (document.webkitExitFullscreen) { /* Chrome, Safari and Opera */
	  document.webkitExitFullscreen();
	} else if (document.msExitFullscreen) { /* IE/Edge */
	  document.msExitFullscreen();
	}
}

export default Journey;
