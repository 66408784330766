import React from 'react';
import 'animate.css';
import 'semantic-ui-css/semantic.min.css';
import {
	Modal,
	Icon,
	Form,
	Grid,
	Button,
	Input,
	Message,
	TextArea
} from 'semantic-ui-react';

import Radar from './Chart';
import RequestManager from './RequestManager';

class Profile extends React.Component {
	
	state = {
		newIndustryExperience: '',
		educationOptions: false,
		userStatus: this.props.userStatus,
		isLoading: false,
		hasError: false,
		errorTitle: '',
		errorMessage: '',
		errorField: '',
		form: {
			firstName: '',
			lastName: '',
			gender: '',
			tax_id: '',
			phone: '',
			region: '',
			city: '',
			englishLevel: '',
			ethnicity: '',
			educationalLevel: '',
			university: '',
			course: '',
			undergradStudent: '',
			currentSemester: '',
			graduationYear: '',
			developmentExperience: '',
			currentEmployment: '',
			linkedinURL: '',
			showPersonality: false,
			professionalExperience: '',
			industryExperience: '',
			salesforceExperience: '',
			trailheadProfileURL: '',
			withDisability: '',
			disabilityDescription: ''
		},
		activeFormStep: 0,
		opennessToExperience: null,
        agreeableness: null,
        extroversion: null,
        conscientiousness: null,
        emotionality: null,
        honestyHumility: null
	};

	previousVisible = null;

	constructor(state){
		super(state);
		this.receivedProfileData = this.receivedProfileData.bind(this);
	}
	
	componentDidMount(){
		setTimeout(() =>this.loadProfile(), 1000);
	}

	// componentDidUpdate(){
	// 	if(this.previousVisible !== this.props.open && this.props.open){
	// 		this.loadProfile();
	// 	}
	// }

	shouldComponentUpdate(nextProps, nextState){
		this.previousVisible = this.props.open;
		this.previousVisiblePersonality = this.props.modalSkills;
		return true;
	}

	loadProfile(){
		this.setState(function(state){
			state.userStatus = this.props.userStatus;
			state.activeFormStep = 0;
			state.isLoading = true;
			state.hasError = false;
			state.errorTitle = '';
			state.errorMessage = '';
			state.errorField = '';
			return state;
		});
		RequestManager.requestAPI(
			'/student/profile/load',
			{},
			this.receivedProfileData
		);
	}

	receivedProfileData(hasError, receivedData) {
		this.setState(function(state){
			state.isLoading = false;
			state.form.firstName = receivedData.firstName || '';
			state.form.lastName = receivedData.lastName || '';
			state.form.gender = receivedData.gender ? receivedData.gender === 'M' ? 'Masculino' : receivedData.gender === 'F' ? 'Feminino' : 'Outro' : '';
			state.form.tax_id = receivedData.taxId || '';
			state.form.phone = receivedData.phoneNumber || '';
			state.form.region = receivedData.region || '';
			state.form.city = receivedData.city || '';
			state.form.linkedinURL = receivedData.linkedinURL || '';
			state.form.educationalLevel = receivedData.educationalLevel || '';
			state.form.englishLevel = receivedData.englishLevel || '';
			state.form.currentEmployment = receivedData.currentEmployment || '';
			state.form.professionalExperience = receivedData.professionalExperience || '';
			state.form.developmentExperience = receivedData.developmentExperience || '';
			state.form.course = receivedData.course || '';
			state.form.ethnicity = receivedData.ethnicity || '';
			state.form.university = receivedData.university || '';
			state.form.currentSemester = receivedData.currentSemester || '';
			state.form.graduationYear = receivedData.graduationYear || '';
			state.form.industryExperience = receivedData.industryExperience ? receivedData.industryExperience.split(',') : [''];
			state.form.salesforceExperience = receivedData.salesforceExperience || '';
			state.form.trailheadProfileURL = receivedData.trailheadProfileURL || '';
			state.form.withDisability = receivedData.withDisability || '';
			state.form.disabilityDescription = receivedData.disabilityDescription || '';
			state.newIndustryExperience = receivedData.industryExperience ? receivedData.industryExperience.split(',') : [''];
			state.opennessToExperience = receivedData.personality.length > 0 ? receivedData.personality.map((personal) => personal.category === 'Openness to experience' ? personal.level : 0).reduce((a, b) => a + b) / 10 : 0;
			state.agreeableness = receivedData.personality.length > 0 ? receivedData.personality.map((personal) => personal.category === 'Agreeableness' ? personal.level : 0).reduce((a, b) => a + b) / 10 : 0;
			state.extroversion = receivedData.personality.length > 0 ? receivedData.personality.map((personal) => personal.category === 'Extraversion' ? personal.level : 0).reduce((a, b) => a + b) / 10 : 0;
			state.conscientiousness = receivedData.personality.length > 0 ? receivedData.personality.map((personal) => personal.category === 'Conscientiousness' ? personal.level : 0).reduce((a, b) => a + b) / 10 : 0;
			state.emotionality = receivedData.personality.length > 0 ? receivedData.personality.map((personal) => personal.category === 'Emotionality' ? personal.level : 0).reduce((a, b) => a + b) / 10 : 0;
			state.honestyHumility = receivedData.personality.length > 0 ? receivedData.personality.map((personal) => personal.category === 'Honesty/Humility' ? personal.level : 0).reduce((a, b) => a + b) / 10 : 0;
			state.showPersonality = Boolean(receivedData.personality.length >= 6);
			return state;
		});
  	}

	closePopUP(){
		this.setState(function(state){
			state.isLoading = false;
			state.hasError = false;
			return state;
		});
	}

	render(){
		return (
		<>
			<Modal 
				closeIcon
				open={this.props.open}
				onClose={()=> this.props.onCloseClick()}
				className="animated fadeInDown"
			>
				<Modal.Header>
					<Icon name="user" />
					Meu Perfil
					<div className="ACTIONS">
						<Button content="Editar" basic onClick={() => {
							this.props.onCloseClick()
							this.props.onOpenModalEdit()
						}} />
					</div>
				</Modal.Header>
				<Modal.Content>
					<div className="CONTAINER">						
						<div className="FORM">
							{
								(this.state.hasError) &&
								<Message negative className="animated fadeInDown">
									<Message.Header>{this.state.errorTitle}</Message.Header>
									<p>{this.state.errorMessage}</p>
								</Message>
							}

							<Form>
								<Grid relaxed columns="2">
									<Grid.Column>
										<Form.Field required>
											<label>Nome</label>
											<Input disabled value={this.state.form.firstName} />
										</Form.Field>
									</Grid.Column>
									<Grid.Column>
										<Form.Field required>
											<label>Sobrenome</label>
											<Input disabled value={this.state.form.lastName} />
										</Form.Field>
									</Grid.Column>
									<Grid.Column>
										<Form.Field required>
											<label>Gênero</label>
											<Input disabled value={this.state.form.gender} fluid />
										</Form.Field>
									</Grid.Column>
									<Grid.Column>
										<Form.Field required>
											<label>CPF</label>
											<Input disabled value={this.state.form.tax_id} />
										</Form.Field>
									</Grid.Column>
									{/* <Grid.Column>
										<Form.Field required>
											<label>Data de Nascimento</label>
											<Input disabled value={this.state.form.birthDate ? new Date(this.state.form.birthDate).toLocaleDateString('pt-BR') : ''} />
										</Form.Field>
									</Grid.Column> */}
									<Grid.Column>
										<Form.Field required>
											<label>Celular (com DDD)</label>
											<Input disabled value={this.state.form.phone} />
										</Form.Field>
									</Grid.Column>
									<Grid.Column>
										<Form.Field required>
											<label>Etnia</label>
											<Input disabled value={this.state.form.ethnicity} />
										</Form.Field>
									</Grid.Column>
									<Grid.Column>
										<Form.Field required>
											<label>Pessoa com necessidades especials?</label>
											<Input disabled value={this.state.form.withDisability} />
										</Form.Field>
									</Grid.Column>
									<Grid.Column>
										<Form.Field required>
											<label>Quais?</label>
											<Input disabled value={this.state.form.disabilityDescription} />
										</Form.Field>
									</Grid.Column>
									<Grid.Column>
										<Form.Field required>
											<label>Região</label>
											<Input disabled value={this.state.form.region} />
										</Form.Field>
									</Grid.Column>
									<Grid.Column>
										<Form.Field required>
											<label>Cidade</label>
											<Input disabled value={this.state.form.city} />
										</Form.Field>
									</Grid.Column>
									<Grid.Column>
										<Form.Field required>
											<label>Qual seu nível de Inglês?</label>
											<Input disabled value={this.state.form.englishLevel} />
										</Form.Field>
									</Grid.Column>
									<Grid.Column>
										<Form.Field required>
											<label>Qual o seu nível de escolaridade</label>
											<Input disabled value={this.state.form.educationalLevel} />
										</Form.Field>
									</Grid.Column>
									{this.state.educationOptions && <>
										<Grid.Column>
											<Form.Field required>
												<label>Em qual Universidade?</label>
												<Input disabled value={this.state.form.university} />
											</Form.Field>
										</Grid.Column>
										<Grid.Column>
											<Form.Field required>
												<label>Curso</label>
												<Input disabled value={this.state.form.course} />
											</Form.Field>
										</Grid.Column>
										{this.state.form.educationalLevel === 'Ensino Superior (cursando)' && <>
											<Grid.Column>
												<Form.Field required>
													<label>Qual período/semestre?</label>
													<Input disabled value={this.state.form.currentSemester} />
												</Form.Field>
											</Grid.Column>
											<Grid.Column>
												<Form.Field required>
													<label>Qual a previsão de conclusão?</label>
													<Input disabled value={this.state.form.graduationYear} />
												</Form.Field>
											</Grid.Column>
										</> }
									</> }
									<Grid.Column>									
										<Form.Field required>
											<label>Qual a sua experiência com desenvolvimento?</label>
											<Input disabled value={this.state.form.developmentExperience} />
										</Form.Field>
									</Grid.Column>
									<Grid.Column>
										<Form.Field required>
											<label>Atualmente você está trabalhando?</label>
											<Input disabled value={this.state.form.currentEmployment} />
										</Form.Field>
									</Grid.Column>
									<Grid.Column>
										<Form.Field required>
											<label>Perfil no LinkedIn (URL)</label>
											<Input disabled value={this.state.form.linkedinURL} />
										</Form.Field>
									</Grid.Column>
									<Grid.Column>
										<Form.Field>
											<label>Em que indústrias você tem experiência?</label>
											<Input disabled value={this.state.newIndustryExperience || ['']} />
										</Form.Field>
									</Grid.Column>
									<Grid.Column>
										<Form.Field required>
											<label>Você possui conhecimento em Salesforce?</label>
											<Input disabled value={this.state.form.salesforceExperience} />
										</Form.Field>
									</Grid.Column>
									<Grid.Column>
										<Form.Field>
											<label>Perfil no Trailhead (URL)</label>
											<Input disabled value={this.state.form.trailheadProfileURL} />
										</Form.Field>
									</Grid.Column>
								</Grid>
								<Grid relaxed columns="1">
									<Grid.Column>
										<Form.Field>
											<label>Faça um resumo da sua Experiência profissional</label>
											<TextArea disabled value={this.state.form.professionalExperience} />
										</Form.Field>
									</Grid.Column>
								</Grid>
							</Form>
						</div>
						{this.state.showPersonality &&
							<div className="RADAR">
								<h3>Modelo de Personalidade</h3>
								<Radar
									firstName={this.state.form.firstName}
									data={{
											labels: [
												'Abertura à Experiência',
												'Afabilidade',
												'Extroversão',
												'Conscienciosidade',
												'Emotividade',
												'Honestidade/Humildade'
											],
											datasets: [{
												label: this.state.form.firstName,
												data: [
													this.state.opennessToExperience,
													this.state.agreeableness,
													this.state.extroversion,
													this.state.conscientiousness,
													this.state.emotionality,
													this.state.honestyHumility
												],
												backgroundColor: '#e5264f88',
												borderColor: '#e5264f88',
												borderWidth: 2,
												pointBackgroundColor: '#fff',
												pointBorderColor: '#e5264f88',
												pointHoverBackgroundColor: '#fff',
												pointHoverBorderColor: 'rgb(54, 162, 235)'
											}]
										}}
								/>
							</div>
						}
					</div>
				</Modal.Content>
			</Modal>
		</>);
	}
}

export default Profile;