import React from 'react'
import { Route, Switch } from 'react-router-dom'

import Dashboard from './Dashboard';
import Journey from './Journey';
import NotFound from './NotFound';
import OAuthCallback from './OAuthCallback';

export default function AppRouter() {
  return (
    <Switch>
        <Route exact path="/" component={Dashboard} />
        <Route path="/journey/:id" component={Journey}/>
        <Route path="/workspace/oauth/callback/" component={OAuthCallback}/>
        <Route component={NotFound} />
    </Switch>
  )
}