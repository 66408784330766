import React from 'react';
import 'animate.css';
import './FaqView.css';
import 'semantic-ui-css/semantic.min.css';
import {
	Modal,
	Button,
	Segment,
    Image,
    Icon,
    Accordion
} from 'semantic-ui-react';

import ReactMarkdown from 'react-markdown';
import CodeBlock from "./CodeBlock";

class FaqView extends React.Component {

	state = {
        faqs: [],
        activeFaqIndex: -1,
		isOpen: this.props.open
	};

	previousVisible = null;

	constructor(state){
		super(state);
		this.handleClick = this.handleClick.bind(this);

    }

	shouldComponentUpdate(nextProps, nextState){
		this.previousVisible = this.props.open;
		return true;
	}

	componentDidUpdate(){
		if(this.previousVisible !== this.props.open && this.props.open){
            var trailData = this.props.trailData;
            var faqStepIndex = this.props.faqStepIndex;
            var faqs = [];
            if (trailData && trailData.steps && trailData.steps.length > faqStepIndex
                    && trailData.faqs && trailData.faqs.length > 0)
            {
                var step = trailData.steps[faqStepIndex];
                if (step.faqs && step.faqs.length > 0){
                    for(var y=0; y<step.faqs.length; y++){
                        var faqId = step.faqs[y];
                        for(var i=0; i<trailData.faqs.length; i++){
                            if (faqId === trailData.faqs[i].id){
                                faqs.push({
                                    content: trailData.faqs[i].content,
                                    answer: trailData.faqs[i].answer
                                });
                            }
                        }        
                    }
                }
            }
            this.setState(function(state){
                state.hasMentorAssigned = this.props.hasMentorAssigned;
                state.faqs = faqs;
                state.activeFaqIndex = -1;
                return state;
            });
		}

    }

    handleClick = (faqIndex) => {
        this.setState(function(state){
            state.activeFaqIndex = state.activeFaqIndex === faqIndex ? -1 : faqIndex;
            return state;
        });
    }

    renderFaq(){
        return <>
        {   
            this.state.faqs.map(function(faq, faqIndex){
                return <Accordion key={faqIndex}>
                    <Accordion.Title
                        className='FAQ_TITLE'
                        active={faqIndex === this.state.activeFaqIndex}
                        index={0}
                        onClick={()=>{this.handleClick(faqIndex)}}
                    >
                        <Icon name='dropdown' />{faq.content}
                    </Accordion.Title>
                    <Accordion.Content
                        className='FAQ_CONTENT'
                        active={faqIndex === this.state.activeFaqIndex}>
                        <ReactMarkdown 
                            source={faq.answer}
                            className='markdown-body'
                            renderers={
                                {
                                    link: props => <a href={props.href} target="_blank" rel="noopener noreferrer">{props.children}</a>,
                                    image: Image,
                                    code: CodeBlock
                                }
                            }
                        />
                    </Accordion.Content>
                </Accordion>;
            },this)
        }
        </>;
    }

    renderShowMessengerQuestion(){
        if (!this.state.hasMentorAssigned){
            return <></>;
        }
        return <Accordion>
            <Accordion.Title
                className='FAQ_TITLE'
                active={this.state.faqs.length === this.state.activeFaqIndex}
                index={0}
                onClick={()=>{this.handleClick(this.state.faqs.length)}}
            >
                <Icon name='dropdown' />Sua dúvida que não está listada aqui?
            </Accordion.Title>
            <Accordion.Content
                className='FAQ_CONTENT'
                active={this.state.faqs.length === this.state.activeFaqIndex}
            >
                <p className='markdown-body'>
                    Se você não encontrou sua pergunta nas dúvidas frequentes, clique no botão abaixo para enviar uma pergunta diretamente ao seu mentor.
                </p>
                <Button color="black" onClick={()=>{this.props.onShowMessenger();}}>
                    Faça uma Pergunta ao seu Mentor
                </Button>
            </Accordion.Content>
        </Accordion>;
    }

	render(){
		return <Modal open={this.props.open} >
			<Segment.Group className="FAQ_VIEWER">
				<Segment className="FAQ_HEADER">
					<Button className="FAQ_BUTTON" onClick={()=>{this.props.onCancelClick();}}>
						Fechar
					</Button>
					Dúvidas frequentes
				</Segment>
				<Segment className="FAQ_CONTAINER">
                    { this.renderFaq() }
                    { this.renderShowMessengerQuestion() }
				</Segment>
			</Segment.Group>
		</Modal>;
	}
}

export default FaqView;