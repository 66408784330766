import React from 'react';
import 'animate.css';
import 'semantic-ui-css/semantic.min.css';
import './Dashboard.css';
import './Markdown.css';
import {
	Menu,
	Image,
	Label,
	Dropdown,
	Card,
	Breadcrumb,
	Progress,
	Grid,
	Icon,
	Modal,
	Form,
	Button,
	TextArea
} from 'semantic-ui-react';

import eIcon from './media/eLogo.png';
import profileIcon from './media/profile.png';
import journeyDefaultImage from './media/journey_default_image.png';

import RequestManager from './RequestManager';
import EditProfile from './EditProfile';
import Profile from './Profile';
import ChangePassword from './ChangePassword';
import ReactMarkdown from 'react-markdown';

class Dashboard extends React.Component {
	state = {
		candidateId: null,
		isLoading: true,
		isInitialized: false,
		userData: {},
		programId: null,
		programTitle: '',
		programStatus: '',
		isProgramOpen: false,
		journeys: [],
		finishedJourneys: [],
		isEditProfileModalOpen: false,
		isViewProfileModalOpen: false,
		isChangePasswordModalOpen: false,
		hasNewJobOpportunities: false,
		jobOpportunityCount: 0,
		opportunities: [],
		showMessage: false,
		openConfirmationModal: false,
		messageConfirmation: '',
		status: '',
		opportunityOrgId: null,
		messageResponse: '',
		resume: true,
		isApprovalPending: false
	};

	constructor(state){
		super(state);
		this.loadDashboard = this.loadDashboard.bind(this);
		this.receivedLoadDashboardData = this.receivedLoadDashboardData.bind(this);
		this.receivedLoadJobOpportunities = this.receivedLoadJobOpportunities.bind(this);
		this.receiveDataStatus = this.receiveDataStatus.bind(this);
		this.setOpenModal = this.setOpenModal.bind(this);
		this.setOpenConfirmationModal = this.setOpenConfirmationModal.bind(this);
		this.setStatus = this.setStatus.bind(this);
		this.refreshData = this.refreshData.bind(this);
		this.onDoneEditProfile = this.onDoneEditProfile.bind(this);
		this.onDoneChangePassword = this.onDoneChangePassword.bind(this);
		this.setModalViewProfileVisible = this.setModalViewProfileVisible.bind(this);
	}

	componentDidMount(){
		this.loadDashboard();
	}

	loadDashboard(){
		RequestManager.requestAPI(
			'/student/loaddashboarddata',
			{},
			this.receivedLoadDashboardData
		);
		RequestManager.requestAPI(
			'/student/load-job-opportunities',
			{},
			this.receivedLoadJobOpportunities
		);
	}

	receivedLoadDashboardData(hasError, receivedData){
		if(!hasError){
			this.setState(function(state){
				state.candidateId = receivedData.candidateId;
				state.userData = receivedData.userData;
				state.programId = receivedData.programId;
				state.programTitle = receivedData.programTitle;
				state.programStatus = receivedData.programStatus;
				state.isProgramOpen = receivedData.isProgramOpen;
				state.journeys = receivedData.journeys;
				state.finishedJourneys = receivedData.finishedJourneys;
				state.isInitialized = true;
				state.hasNewJobOpportunities = receivedData.hasNewJobOpportunities;
				state.jobOpportunityCount = receivedData.jobOpportunityCount;
				state.isApprovalPending = receivedData.isApprovalPending;
				return state;
			});
		}else{
			RequestManager.goBackToLogin();
		}
	}

	receivedLoadJobOpportunities(hasError, receivedData){
		if(!hasError){
			this.setState(function(state){
				state.opportunities = receivedData.opportunities;
				return state;
			});
		}
	}

	receiveDataStatus(hasError, receivedData){
		if(!hasError){
			window.location.reload()
		} else {
			console.error('Error: '+hasError);
		}
	}

	refreshData(){
		this.setState((state) => {
			state.isEditProfileModalOpen = false;
			return state
		})
		this.loadDashboard();
	}

	setModalEditProfileVisible(){
		this.setState(function(state){
			state.isEditProfileModalOpen = !this.state.isEditProfileModalOpen;
			return state;
		});
	}

	setModalViewProfileVisible(){
		this.setState(function(state){
			state.isViewProfileModalOpen = !this.state.isViewProfileModalOpen;
			return state;
		});
	}

	onDoneEditProfile(){
		this.setModalEditProfileVisible();
		this.loadDashboard();
	}

	setModalChangePasswordVisible(isVisible){
		this.setState(function(state){
			state.isChangePasswordModalOpen = isVisible;
			return state;
		});
	}

	setOpenModal(){
		this.setState(function(state){
			state.hasNewJobOpportunities = !this.state.hasNewJobOpportunities;
			return state;
		});
	}

	setOpenConfirmationModal(){
		this.setState(function(state){
			state.openConfirmationModal = !this.state.openConfirmationModal;
			return state;
		});
	}

	setStatus(){
		RequestManager.requestAPI(
			'/student/job-opportunity/send-answer',
			{
				orgId: this.state.opportunityOrgId, 
				candidateId: this.state.candidateId, 
				status: this.state.status, 
				message: this.state.messageResponse,
			},
			this.receiveDataStatus
		);
	}

	onDoneChangePassword(){
		this.setModalChangePasswordVisible(false);
		this.loadDashboard();
	}

	logout(){
		RequestManager.logout();
	}

	getJourneyPhase(phase){
		switch (phase){
			case 'I':
				return 'Ignite';
			case 'A':
				return 'Accelerate';
			case 'L':
				return 'Launch';
			default:
				return 'Discovery';
		}
	}

	formatMinutes(minutes){
		if (minutes != null){
			var hours = 0;
			if (minutes >= 60){
				hours = parseInt(minutes / 60);
				minutes = minutes % 60;
			}
			return (hours > 0 ? hours + ' hr' + (hours > 1 ? 's ':' ') : '') + (minutes > 0 ? minutes + ' min' : '');
			//return '~' + (hours > 0 ? hours+'h' : '') + (minutes > 0 ? minutes+'min' : '');
		}
		return null;
	}

	renderTopMenu(){
		return (
			<Menu fixed="top" inverted borderless size="large" className="MENU_TOP">
				<Menu.Item className="MENU_BRAND">
					<Image src={eIcon} />
				</Menu.Item>
				<Menu.Item>
					<div className="MENU_BREADCRUMB">
						<Breadcrumb>
							<Breadcrumb.Section>{this.state.programTitle}</Breadcrumb.Section>
						</Breadcrumb>
					</div>
				</Menu.Item>

				<Menu.Menu position="right" >
					{+this.state.jobOpportunityCount > 0 &&
						<Menu.Item onClick={this.setOpenModal}>
							<Icon.Group size='large' className="MENU_JOB">
								<Icon inverted name='travel' />
								<Icon corner name='add' color='black' />
							</Icon.Group>
						</Menu.Item>
					}
					<Dropdown
						item icon={null}
						trigger={
							<>
								<span className="HIDE_ON_MOBILE">
									{this.state.userData.firstName}
								</span>
								<Image avatar src={profileIcon} className="DD_AVATAR" /> 
							</>
						}
						className="DD_MENU_BUTTON"
					>
						<Dropdown.Menu className="DD_MENU">
							<Dropdown.Header icon="user" content={this.state.userData.firstName + ' '  + this.state.userData.lastName} className="DD_MENU_LABEL" />
							<Dropdown.Header icon="mail" content={this.state.userData.email} className="DD_MENU_LABEL" />
							<Dropdown.Header icon="trophy" content={this.state.programTitle}  className="DD_MENU_LABEL" />
							<Dropdown.Divider />
							<Dropdown.Item icon="user" text='Meu Perfil' className="DD_MENU_ITEM" onClick={ ()=>{this.setModalViewProfileVisible()} } />
							<Dropdown.Item icon="lock" text='Trocar Senha' className="DD_MENU_ITEM" onClick={ ()=>{this.setModalChangePasswordVisible(true)} } />
							<Dropdown.Item icon="power off" text='Sair' className="DD_MENU_ITEM" onClick={this.logout} />
						</Dropdown.Menu>
					</Dropdown>
				</Menu.Menu>
			</Menu>
		)
	}

	renderJourneyCards(showApprovalCard, journeys, title){
		if (journeys === null || journeys.length < 1){
			return <></>;
		}
		return <>
			<h2>{title}</h2>

			<Card.Group itemsPerRow={3} stackable={true} doubling={true}>
				{showApprovalCard && <Card>							
					<Card.Content className="JOURNEY_CARD_PENDING">
						<Icon name="eye" size="huge" />

						<Card.Description className="JOURNEY_MESSAGE_PENDING">
							Aguardando processo de aprovação...
						</Card.Description>
					</Card.Content>
				</Card>}

				{
					journeys.map(function(journey, journeyIndex){

						var percentExecuted = 0;
						var cardPoints = <></>;
						var estimatedTimeLeft = journey.estimatedTime;
						if (journey.isComplete){
							percentExecuted = 100;
							if (journey.score !== null && journey.score > 0){
								cardPoints = <div className="JOURNEY_CARD_POINTS">
									<Label circular className="JOURNEY_CARD_SCORE">&nbsp;+{journey.score} pontos &nbsp;</Label>
								</div>;
							}
						}else{
							if (journey.points !== null && journey.points > 0){
								cardPoints = <div className="JOURNEY_CARD_POINTS">
									<Label circular>&nbsp;+{journey.points} pontos &nbsp;</Label>
								</div>;
							}							
							if(journey.estimatedTime != null
										&& journey.estimatedTime > 0
											&& journey.executedTime != null
												&& journey.executedTime > 0)
							{
								percentExecuted = Math.ceil((journey.executedTime / journey.estimatedTime) * 100);
								estimatedTimeLeft -= journey.executedTime;
								if (estimatedTimeLeft < 0) estimatedTimeLeft = 0;
							}
						}
						var cardContent = <Card.Content>

							{ cardPoints }

							<div className="JOURNEY_CARD_IMAGE">
								<Image
								src={journey.imagePath !== null && journey.imagePath > ' ' ? journey.imagePath : journeyDefaultImage}
								/>
							</div>
							<Card.Meta className="JOURNEY_CARD_META">
								{this.getJourneyPhase(journey.phase)}
							</Card.Meta>
							<Card.Description className="JOURNEY_CARD_DESCR">
								<h3>{journey.title}</h3>
								{journey.description}
							</Card.Description>
							<Card.Meta className="JOURNEY_CARD_META" align="left">
								<Grid columns='equal'>
									<Grid.Column>
									{	journey.isUnlocked === false
										?	<Label circular><Icon name="lock" /></Label>
										:	percentExecuted > 0
											?	<Progress align="left" className="JOURNEY_PROGRESS_BAR" progress='percent' percent={percentExecuted} />
											:	<></>
									}
									</Grid.Column>
									<Grid.Column align="right">
										{journey.isComplete ? "" : "~"+this.formatMinutes(estimatedTimeLeft)}
									</Grid.Column>
								</Grid>
							</Card.Meta>
						</Card.Content>;
						if (journey.isUnlocked){
							return <Card
								key={journey.id}
								href={"/journey/"+journey.id}
								className={"JOURNEY_CARD"+(journey.isComplete?" COMPLETED":"")}
							>
								{cardContent}							
							</Card>;
						}else{
							return <Card
								key={journey.id}
								className="JOURNEY_CARD LOCKED"
							>
								{cardContent}							
							</Card>;
						}
					}, this)
				}
			</Card.Group>
		</>;
	}

	renderModals(){
		return (
			<>
				<Modal
					closeIcon
					onOpen={this.setOpenModal}
					onClose={this.setOpenModal}
					open={this.state.hasNewJobOpportunities}
				>
					<Modal.Header className='OPPORTUNITY-TITLE'>
						Oportunidades de Trabalho
					</Modal.Header>
					<Modal.Content className='NO-PADDING'>
					{this.state.opportunities.length > 0 && this.state.opportunities.map((opportunity, index) => (		
						<div key={index}>
							<Card fluid className="NO-BORDER CARD-JOB">
								<Card.Content>
									<Card.Header>{opportunity.jobTitle}</Card.Header>
									<Card.Meta>
										<a className='UNDERLINE' href={opportunity.websiteURL} target="_blank" rel="noopener noreferrer">
											{opportunity.companyName}
										</a>
										&nbsp;
										<a href={opportunity.linkedinURL} target="_blank" rel="noopener noreferrer">
											<Icon name='linkedin' color='blue' />
										</a>
										&nbsp;&nbsp;&nbsp;
										{opportunity.location} &nbsp; &bull; {opportunity.workModel === 'R' ? 'Remoto' : opportunity.workModel === 'P' ? 'Presencial' : 'Híbrido'}
									</Card.Meta>									
									<Card.Description>
										<ReactMarkdown 
											source={opportunity.message.length > 300 && this.state.resume ? opportunity.message.substring(0, 300) + '...' : opportunity.message}
											className={'markdown-body MARKDOWN-CONTENT-JOB'}
										/>

										<p className='RESUME'>
											{opportunity.message.length > 300 &&
												<>
													<span onClick={()=> {
														this.setState((state) => {
															state.resume = !this.state.resume;
															return state
														})
													}}>
														{this.state.resume ? 'Mais...' : 'Menos...'}
													</span>
													|&nbsp;&nbsp;
												</>
											}
											{
												opportunity.status === '2' &&
												<>
													<Icon name='clock outline' />Expira em {opportunity.expirationTime}
												</>
											}
											{
												opportunity.status === '3' && <>
													<Icon name='thumbs up outline' color='green'/>
												</> 
											}
											{
												opportunity.status === 'D' && <>
													<Icon name='thumbs down outline' color='red'/>
												</> 
											}
										</p>

										{opportunity.status === '2' && <>
											<Button
												content="Quero me candidatar"
												onClick={() => { 
													this.setState((state) => {
														state.messageConfirmation = 'ACEITAR'
														state.status = '3'
														state.opportunityOrgId = opportunity.orgId
														state.messageResponse = 'Olá agradeço pelo contato e aguardo anciosamente para darmos andamento a próxima etapa!'
														return state
													})
													this.setOpenConfirmationModal()
												}}
												primary
											/>
											<Button
												content='Não tenho interesse'
												onClick={() => { 
													this.setState((state) => {
														state.messageConfirmation = 'REJEITAR'
														state.status = 'D'
														state.opportunityOrgId = opportunity.orgId
														state.messageResponse = ''
														return state
													})
													this.setOpenConfirmationModal()										
												}}
											/>
										</>}
									</Card.Description>
								</Card.Content>
							</Card>
							<hr className="BORDER-BOTTOM"/>
						</div>
					))}
					</Modal.Content>
				</Modal>
				<Modal
					onOpen={this.setOpenConfirmationModal}
					onClose={this.setOpenConfirmationModal}
					open={this.state.openConfirmationModal}
				>
					<Modal.Header>
						<Icon name='folder open outline' size="large" />
						Deseja <strong>{this.state.messageConfirmation}</strong> o convite?
					</Modal.Header>
					<Modal.Content>
						<Form>
							<TextArea rows={5}
								required
								value={this.state.messageResponse}
								onChange={(event) => {
									this.setState((state, {value}) => {
										state.messageResponse = value
										return state
									})
								}}
							/>
						</Form>
					</Modal.Content>
					<Modal.Actions>
						<Button
							content="Confirmar"
							labelPosition='right'
							icon='checkmark'
							onClick={() => {
								this.setStatus()
								this.setOpenModal()
								this.setOpenConfirmationModal()
							}}
							primary
						/>
						<Button 
							content="Voltar"
							labelPosition='right'
							icon='undo'
							onClick={this.setOpenConfirmationModal}
							basic
						/>
					</Modal.Actions>
				</Modal>
			</>
		)
	}

	render(){
		document.title = 'My Pragma';
		if(!this.state.isInitialized){
			return <></>;
		}else if(this.state.userData.status === "E"){
			return  <div className="MAIN_FRAME">
				<ChangePassword
					open
					userStatus={this.state.userData.status}
					onDone={this.refreshData}
				/>
			</div>;
		}else if(this.state.userData.status === "P" || this.state.userData.hasDataPending){
			return  <div className="MAIN_FRAME">
				<EditProfile
					open
					userStatus={this.state.userData.status}
					onDone={this.refreshData}
				/>
			</div>;
		}else{
			return <div className="MAIN_FRAME">

					{ this.renderTopMenu() }

					<div className="DASHBOARD_CONTAINER">

						<div className="DASHBOARD_WELCOME">
							Olá {this.state.userData.firstName}! Vamos aprender algo novo hoje! &nbsp;: )
						</div>

						<div className="JOURNEY_LIST">
							{ this.renderJourneyCards(this.state.isApprovalPending, this.state.journeys, "Jornada"+(this.state.journeys.length > 1 ? "s":"")+" a percorrer...") }
							{ this.renderJourneyCards(false, this.state.finishedJourneys, "Caminho percorrido ...") }
						</div>
			
					</div>

					{ this.renderModals() }

					<Profile
						open={this.state.isViewProfileModalOpen}
						onOpenModalEdit={() => this.setModalEditProfileVisible()}
						onCancelClick={() => this.setModalViewProfileVisible()}
						onCloseClick={() => this.setModalViewProfileVisible()}
					/>

					<EditProfile
						open={this.state.isEditProfileModalOpen}
						userStatus={this.state.userData.status}
						onDone={this.refreshData}
						onCancelClick={()=>{ this.setModalEditProfileVisible() }}
					/>

					<ChangePassword
						userStatus={this.state.userData.status}
						open={this.state.isChangePasswordModalOpen} 
						onDone={this.onDoneChangePassword} 
						onCancelClick={()=>{ this.setModalChangePasswordVisible(false) }} 
					/>
				</div>
		}
	}
}

export default Dashboard;
