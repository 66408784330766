import React from 'react';
import 'animate.css';
import 'semantic-ui-css/semantic.min.css';
import {
	Modal,
	Icon,
	Button,
	Message
} from 'semantic-ui-react';
import RequestManager from './RequestManager';

class WorkspaceConnect extends React.Component {

	state = {
        journeyId: this.props.journeyId,
        trailId: this.props.trailId,
		workspaceData: this.props.workspaceData,
		challenges: null,
		evaluationControl: null,
		trailData: null,
        isLoading: false,
		hasError: false,		
        errorTitle: '',
		errorMessage: ''
	};

	previousVisible = null;
	workspaceOAuthLogin = null;
	workspaceOAuthTimeout = null;
	workspaceOAuthCounter = null;

	constructor(state){
		super(state);
		this.cancelButtonHandler = this.cancelButtonHandler.bind(this);
		this.handleValueChange = this.handleValueChange.bind(this);
		this.createSession = this.createSession.bind(this);
		this.refreshSession = this.refreshSession.bind(this);
		this.listenOAuthCallback = this.listenOAuthCallback.bind(this);
		this.receivedRefreshSessionData = this.receivedRefreshSessionData.bind(this);			
		this.receivedCreateSessionData = this.receivedCreateSessionData.bind(this);
		this.evaluationStart = this.evaluationStart.bind(this);
		this.evaluationExecute = this.evaluationExecute.bind(this);
		this.evaluationFinish = this.evaluationFinish.bind(this);
		this.receivedEvaluationStartData = this.receivedEvaluationStartData.bind(this);
		this.receivedEvaluationExecuteData = this.receivedEvaluationExecuteData.bind(this);
		this.receivedEvaluationFinishData = this.receivedEvaluationFinishData.bind(this);
	}

	shouldComponentUpdate(nextProps, nextState){
		this.previousVisible = this.props.open;
		return true;
	}

	componentDidUpdate(){
		if(this.previousVisible !== this.props.open && this.props.open){
			this.setState(function(state){
                state.journeyId = this.props.journeyId;
                state.trailId = this.props.trailId;
				state.workspaceData = this.props.workspaceData;
				state.challenges = null;
				state.evaluationControl = null;
				state.hasError = false;
				state.errorTitle = '';
				state.errorMessage = '';
				return state;
			});
			this.refreshSession();
		}
	}

	refreshSession(){
		RequestManager.requestAPI(
			'/student/workspace/oauth/session',
			{
				journeyId: this.state.journeyId,
				action: "refresh"
			},
			this.receivedRefreshSessionData
		);
	}

	receivedRefreshSessionData(hasError, receivedData){
		if(!hasError){
			if (receivedData.workspace !== null){
				this.setState(function(state){
					state.workspaceData = receivedData.workspace;
					return state;
				});
				if (receivedData.workspace.isConnected === true){
					this.evaluationStart();
					return;
				}else if (receivedData.workspace.authorizeURL !== null
							&& receivedData.workspace.authorizeURL > " ")
				{
					this.openOAuthLogin();
					return;
				}
			}
		}else{
			this.failedOnExecution();
		}

	}

	openOAuthLogin(){
		if (this.state.workspaceData.authorizeURL === null
			|| this.state.workspaceData.authorizeURL <= " "
				|| this.state.workspaceData.callbackURL === null
					|| this.state.workspaceData.callbackURL <= " "
						|| this.state.workspaceData.clientId === null
							|| this.state.workspaceData.clientId <= " ")
		{
			return false;
		}
		if (this.workspaceOAuthLogin !== null){
			this.workspaceOAuthLogin.close();
			this.workspaceOAuthLogin = null;
		}
		if (this.workspaceOAuthTimeout !== null){
			clearTimeout(this.workspaceOAuthTimeout);
			this.workspaceOAuthTimeout = null;
		}
		this.workspaceOAuthCounter = 0;
	
		var authorizeURL = this.state.workspaceData.authorizeURL +
			"&client_id="+encodeURIComponent(this.state.workspaceData.clientId) +
			"&redirect_uri="+encodeURIComponent(this.state.workspaceData.callbackURL);

		this.workspaceOAuthLogin = window.open(authorizeURL, "WORKSPACE_AUTHORIZE");
		this.workspaceOAuthTimeout = setTimeout(
			this.listenOAuthCallback,
			100
		);
		return true;
	}

	listenOAuthCallback() {
		if (this.workspaceOAuthLogin !== null && this.state.workspaceData.callbackURL !== null){
			this.workspaceOAuthTimeout = setTimeout(
				this.listenOAuthCallback,
				500
			);
			try {
				var workspaceCallbackURL = this.workspaceOAuthLogin.location.href;
				if (workspaceCallbackURL !== undefined
						&& workspaceCallbackURL !== null
							&& workspaceCallbackURL.substr(0,this.state.workspaceData.callbackURL.length) === this.state.workspaceData.callbackURL
				){
					this.workspaceOAuthLogin.close();
					if (this.workspaceOAuthTimeout !== null){
						clearTimeout(this.workspaceOAuthTimeout);
						this.workspaceOAuthTimeout = null;
					}
					this.createSession(workspaceCallbackURL);
				}else if (workspaceCallbackURL === undefined){
					this.workspaceOAuthLogin.close();
					if (this.workspaceOAuthTimeout !== null){
						clearTimeout(this.workspaceOAuthTimeout);
						this.workspaceOAuthTimeout = null;
					}
					this.failedOnExecution();
				}
			}catch(err) {
				console.error(err);
			}
		}
	}

	createSession(workspaceCallbackURL){
		RequestManager.requestAPI(
			'/student/workspace/oauth/session',
			{
				journeyId: this.state.journeyId,
				action: "create",
				authorizationCode : this.getURLParameter("code",workspaceCallbackURL),
				accessToken : this.getURLParameter("access_token",workspaceCallbackURL),
				refreshToken : this.getURLParameter("refresh_token",workspaceCallbackURL),
				instanceURL : this.getURLParameter("instance_url",workspaceCallbackURL),
				instanceId : this.getURLParameter("id",workspaceCallbackURL)
			},
			this.receivedCreateSessionData
		);
	}

	receivedCreateSessionData(hasError, receivedData){
		if(hasError){
			console.error(hasError);
			this.failedOnExecution();
			return;
		}
		if (receivedData.workspace !== null){
			this.setState(function(state){
				state.workspaceData = receivedData.workspace;
				return state;
			});
			if (receivedData.workspace.isConnected === true){
				this.props.onWorkspaceCreateSession(receivedData);
				this.evaluationStart();
			}else{
				this.failedOnExecution();
			}
		}
	}

	evaluationStart(){
		this.setState(function(state){
			state.evaluationControl = {
				status: "start",
				percentComplete: 0,
				activeChallengeIndex: -1,
				activeSequenceIndex: -1
			};
			return state;
		});
		RequestManager.requestAPI(
			'/student/challenge/evaluation',
			{
				journeyId: this.state.journeyId,
				trailId: this.state.trailId,
				action: "start"
			},
			this.receivedEvaluationStartData
		);
	}

	receivedEvaluationStartData(hasError, receivedData){
		if(hasError){
			console.error(hasError);
			this.failedOnExecution();
			return;
		}
		if (receivedData.challenges !== null){
			this.setState(function(state){
				state.challenges = receivedData.challenges;
				return state;
			});
			this.evaluationExecute();
		}
	}

	evaluationExecute(){
		if (this.state.challenges === null || this.state.evaluationControl === null){
			this.failedOnExecution();
			return;
		}
		
		var activeChallengeIndex = this.state.evaluationControl.activeChallengeIndex;
		var activeSequenceIndex = this.state.evaluationControl.activeSequenceIndex;
		var challenge = null;
		if (activeChallengeIndex < 0){
			activeChallengeIndex = 0;
			activeSequenceIndex = 0;
		}else if (activeChallengeIndex < this.state.challenges.length){
			challenge = this.state.challenges[activeChallengeIndex];
			if (challenge.evaluations !== null){
				activeSequenceIndex++;
				if (activeSequenceIndex >= challenge.evaluations.length){
					activeChallengeIndex++;
					activeSequenceIndex = 0;
				}
			}
		}
		if (activeChallengeIndex < this.state.challenges.length){
			challenge = this.state.challenges[activeChallengeIndex];
			if (challenge.evaluations !== null && activeSequenceIndex < challenge.evaluations.length){
				this.setState(function(state){
					state.evaluationControl = {
						status: "execute",
						percentComplete: 0,
						activeChallengeIndex: activeChallengeIndex,
						activeSequenceIndex: activeSequenceIndex
					};
					return state;
				});
				RequestManager.requestAPI(
					'/student/challenge/evaluation',
					{
						journeyId: this.state.journeyId,
						trailId: this.state.trailId,
						challengeId: challenge.id,
						sequence: challenge.evaluations[activeSequenceIndex].sequence,
						action: "execute"
					},
					this.receivedEvaluationExecuteData
				);
				return;			
			}
		}
		this.evaluationFinish();
	}

	receivedEvaluationExecuteData(hasError, receivedData){
		if(hasError){
			console.error(hasError);
			this.failedOnExecution();
			return;
		}else if (receivedData.challengeId === null || receivedData.hasErrors === null){
			this.failedOnExecution();
			return;
		}
		if (receivedData.hasErrors === true
				&& this.state.challenges !== null
					&& this.state.evaluationControl.activeChallengeIndex < this.state.challenges.length)
		{
			var challenge = this.state.challenges[this.state.evaluationControl.activeChallengeIndex];
			if (challenge.evaluations !== null){
				var activeChallengeIndex = challenge.evaluations.length;
				this.setState(function(state){
					state.evaluationControl.activeChallengeIndex = activeChallengeIndex;
					return state;
				});		
			}
		}else if (receivedData.executePending !== null && receivedData.executePending > ' '){
			// prov
			this.setState((state) => {
				state.activeChallengeIndex = this.state.evaluationControl.activeChallengeIndex;
				state.activeSequenceIndex = this.state.evaluationControl.activeSequenceIndex;
				return state;
			})

			if (this.state.activeChallengeIndex < this.state.challenges.length){
				challenge = this.state.challenges[this.state.activeChallengeIndex];
				RequestManager.requestAPI(
					'/student/challenge/evaluation',
					{
						journeyId: this.state.journeyId,
						trailId: this.state.trailId,
						challengeId: challenge.id,
						sequence: challenge.evaluations[this.state.activeSequenceIndex].sequence,
						action: "execute",
						executePending: receivedData.executePending
					},
					this.receivedEvaluationExecuteData
				);
			}
			return;
		}
		this.evaluationExecute();
	}

	evaluationFinish(){
		this.setState(function(state){
			state.challenges = null;
			return state;
		});
		RequestManager.requestAPI(
			'/student/challenge/evaluation',
			{
				journeyId: this.state.journeyId,
				trailId: this.state.trailId,
				action: "finish"
			},
			this.receivedEvaluationFinishData
		);
	}

	receivedEvaluationFinishData(hasError, receivedData){
		if(hasError){
			console.error(hasError);
			this.failedOnExecution();
			return;
		}
		if (receivedData.challenges !== null){
			this.setState(function(state){
				state.evaluationControl = {
					status: "finished",
					percentComplete: 100
				};	
				state.trailData = {
					hasActivityPending: receivedData.hasActivityPending,
					hasQuestionPending: receivedData.hasQuestionPending,
					isComplete: receivedData.isComplete,
					score: receivedData.score
				};
				state.challenges = receivedData.challenges;
				return state;
			});
		}
		this.props.onChallengeEvaluationFinish(receivedData);
		this.props.onCancelClick();
	}

	failedOnExecution(){
		this.setState(function(state){
			state.hasError = true;
			state.errorTitle = 'Não foi possível conectar!';
			state.errorMessage = 'Tente novamente em alguns instantes, caso o problema persista contate a equipe da Pragma pelo email: equipe@pragma.school.';
			return state;
		});
	}

	handleValueChange(value, name){
		const data = value;
		this.setState(function(state){
			if(data > ' '){
				state.form[name] = data;
			}else{
				state.form[name] = null;
			}
			return state;
		});
	}

	getURLParameter(name,url) {
		name = name.replace(/[[]/, '\\[').replace(/[\]]/, '\\]');
		var regex = new RegExp('[\\?&#]' + name + '=([^&#]*)');
		var results = regex.exec(url);
		return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
	}

	setLoadingState(isLoading){
		this.setState(function(state){
			state.isLoading = isLoading;
			return state;
		});
	}

    cancelButtonHandler(){
        this.props.onCancelClick();
		this.setState(function(state){
			state.isLoading = false;
			return state;
		});
    }

	render(){
		var content = <></>;
		if (this.state.hasError){
			content = <Message negative className="animated fadeInDown">
				<Message.Header>{this.state.errorTitle}</Message.Header>
				<p>{this.state.errorMessage}</p>
			</Message>;
		}else{
			var message;
			if (this.state.evaluationControl === null){
				message = "Iniciando! ...";
			}else if (this.state.evaluationControl.status === "start"){
				message = "Aguarde! Conectando com "+this.state.workspaceData.label+" ...";
			}else if (this.state.evaluationControl.status === "execute"){
				message = "Aguarde! Verificando atividades do projeto ...";
			}else if (this.state.evaluationControl.status === "finished"){
				message = "Aguarde! Finalizando a avaliação ...";
			}else{
				message = "Concluído!";
			}
			content = <div>{ message }</div>;
		}

		return <Modal open={this.props.open} className="animated fadeInDown" size="small" >
			<Modal.Header>
				<Icon name="cog" />
				{this.state.workspaceData.label} Connection
			</Modal.Header>
			<Modal.Content>
				{content}
			</Modal.Content>
			<Modal.Actions>
				<Button disabled={this.state.isLoading} onClick={this.cancelButtonHandler}>
					Cancelar
				</Button>
			</Modal.Actions>
		</Modal>;
	}
}

export default WorkspaceConnect;